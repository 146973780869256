
import { defineComponent, reactive, computed, onMounted, watch } from "vue";
import { ethitransService } from "@/services/EthitransService";

import TitleComponent from "@/components/formComponents/TitleComponent.vue";
import TextBoxComponent from "@/components/formComponents/TextBoxComponent.vue";
// import TextAreaComponent from "@/components/formComponents/TextAreaComponent.vue";
import RadioButtonComponent from "@/components/formComponents/RadioButtonComponent.vue";
import SelectOption from "@/components/formComponents/SelectOption.vue";
import SuccessfulModalCustom from "@/components/modal/SuccessfulModalCustom.vue";
import CheckBox from "@/components/formComponents/CheckBox.vue";
import TextArea from "@/components/formComponents/TextArea.vue";
import ErrorList from "@/components/commonDesign/ErrorList.vue";
import { common } from "@/common/services/Common";
import { useRouter } from "vue-router";
// import Pagination from 'v-pagination-3';
import VPagination from "vue3-pagination";
import "vue3-pagination/dist/vue3-pagination.css";
import Multiselect from 'vue-multiselect';

import { useStore } from "vuex";
import introJs from "intro.js";

// import FormView from "@/components/cards/FormView.vue";
export default defineComponent({
  name: "GuestUserManage",
  components: {
    Multiselect,
    ErrorList,
    TitleComponent,
    TextBoxComponent,
    // TextAreaComponent,
    RadioButtonComponent,
    SuccessfulModalCustom,
    SelectOption,
    CheckBox,
    TextArea,
    // 'pagination': Pagination,
    VPagination,
    // FormView,
    // Multiselect
  },
  setup() {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const moment = require("moment");
    const store = useStore();
    const router = useRouter();
    const state = reactive({
      userList: [] as any,
      data: {
        guestUserListLoad: false,
        showSuccesCustomModal: false,
        successFulMessage: "",
        selectedDate: "" as any,
        selectedGuestUser: {} as any,
        templateResponse: {} as any,
        userList: [] as any,
        guestUserList: [] as any,
        clientDocuments: [] as any,
        additionalMessage: "",
        privacyList: [
          {
            name: "Private to this channel",
            code: "channel",
            value: "private to this channel",
          },
          {
            name: "Share within this project",
            code: "project",
            value: "share within this project",
          },
          { name: "Share to Public", code: "public", value: "share to public" },
        ],
        privacy: {
          name: "Private to this channel",
          value: "private to this channel",
          code: "channel",
        },
      },
      name: "" as any,
      email: "" as any,
      isShow: false,
      formId: "",
      selectedFormId: "",
      type: "",
      searchBy: "",
      filter: {
        type: "all",
        projectId: "" as any,
        channelId: "" as any,
        sortBy: -1,
        title: 1,
        selected: { name: "Newest First", id: -1 } as any,
        defaultValue: false
      },
      formData: {} as any,
      formList: {} as any,
      invite: {
        users: [] as any,
      },
      existingMember: {} as any,
      item: {
        sendOption: {
          project: {} as any,
          channel: {} as any,
        },
        error: {
          erorListSendForm: [] as any,
          updateValidity: [] as any,
        },
        hasError: false,
        selectedUserFormDetails: [] as any,
        searchUser: "",
        paging: {
          getAccountFormList: {
            perPage: 10,
            totalDocs: 0,
            totalPages: 0,
            currentPage: 1,
          },
        },
      },
      selectedElement: {
        element: {} as any,
        type: "",
        index: "",
        title: "",
        description: "",
      },
      userFormData: [] as any,
      projectList: [] as any,
      sortList: [
        {
          name: "Newest First",
          id: -1,
        },
        {
          name: "Oldest First",
          id: 1,
        },
        {
          name: "Form Name (A>Z)",
          id: 1,
        },
        {
          name: "Form Name (Z>A)",
          id: -1,
        },
      ],
      currentSendUser: false,
      selectedUser: {},
      activeUserIndex: 0,
      channelListForSendOption: [] as any,
      userListForSendOption: [] as any,
      channelList: [] as any,
      allChannelList: [] as any,
      newFormData: {
        title: "",
        description: "",
        elements: [] as any,
      },
      ui: {
        isLoading: {
          clientDocumentRetrieveWorkflow: false,
          getAccountFormList: false,
          clientDocumentList: false,
          accountFormResponseByEmailAndId: false,
        },
        postLoading: {
          setNewValidity: false,
          getAccountFormList: false,
          getAccountFormRetrieveById: false,
          getprojectList: false,
          getChannelByProject: false,
          getChannelByProjectForSendOption: false,
          getChannelList: false,
        },
        isPostLoading: {
          createFormTemplate: false,
          formMessage: false,
          getUserListByProjectAndChannel: false,
          sendDigitizedForm: false,
          getAccountFormRetrieveById: false,
          selectedUserAccountFormResponse: false,
        },
      },
    });
    function toggleTourGuide() {
      if (store.state.showTourGuide) {
        introJs()
          .setOptions({
            steps: [
              {
                title: "Welcome ",
                intro:
                  "Welcome to Guest User Manage! 👋 <iframe width='426' height='240' src='https://www.youtube.com/embed/G8vkPCn2bLs' title='Slice Text in Photoshop' frameborder='0' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture' allowfullscreen></iframe>",
              },
              {
                title: "Manage Guest Users",
                element: document.getElementById(
                  "filterManageGuestUser"
                ) as HTMLInputElement,
                intro: "Manage Guest Users By Project and Channel.",
              },
              {
                title: "User List",
                element: document.getElementById(
                  "filteredGuestUsers"
                ) as HTMLInputElement,
                intro: "Guest Users and manage the users.",
              },
            ],
          })
          .start();
      }
    }
    watch(
      // () => watchTour,
      () => store.state.takAtour,
      (newvalue, oldvalue) => {
        if (newvalue === true) {
          console.log("check >>>", newvalue);
          toggleTourGuide();
        }
      }
    );

    function closeSuccessCustomModal() {
      state.data.showSuccesCustomModal = !state.data.showSuccesCustomModal;
      $("#SuccessfulModalCustom").modal("hide");
    }
    // eslint-disable-next-line vue/return-in-computed-property
    const guestUsers = computed(() => {
      return state.data.guestUserList;
    });
    // eslint-disable-next-line vue/return-in-computed-property
    const formPrivacyMessage = computed(() => {
      if (state.data.privacy.value === "private to this channel") {
        return "This form will remain private to this channel. Form will not be visible to other channels of this project.";
      } else if (state.data.privacy.value === "share within this project") {
        return " This form will be shared in this project so that other channels of this project can utilize this form, but will not be shared with other projects.";
      } else {
        return "This form will be shared to public.";
      }
    });
    const sentToUsers = computed(() => {
      if (state.formData.sentToUsers && state.formData.sentToUsers.length) {
        return state.formData.sentToUsers.filter((form: any) => {
          if (form.email) {
            return form.email
              .toLowerCase()
              .match(state.item.searchUser.toLowerCase());
          }
        });
      } else {
        return [];
      }
    });
    const templateList = computed(() => {
      if (state.formList.docs && state.formList.docs.length) {
        return state.formList.docs.filter((form: any) => {
          // if (form.title) {
          //   return form.title.toLowerCase().match(state.searchBy.toLowerCase());
          // }
          return form;
        });
      } else {
        return [];
      }
    });

    function getAccountFormList() {
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        project_id: state.filter.projectId,
        // eslint-disable-next-line @typescript-eslint/camelcase
        channel_id: state.filter.channelId,
      };
      // console.log("FINAL Payload", payLoad)
      state.ui.postLoading.getAccountFormList = true;
      ethitransService
        .guestUserList(payLoad)
        .then((res: any) => {
          console.log("guestUserList Response WOWO: >>> ", res.data);
          state.data.guestUserList = res.data.data ? res.data.data : [];
          // if (res.data.totalPages) {
          //   state.item.paging.getAccountFormList.totalPages =
          //     res.data.totalPages;
          // }
          // if (res.data.totalDocs) {
          //   state.item.paging.getAccountFormList.totalDocs =
          //     res.data.totalDocs;
          // }
          // res.data.projects;
          // getChannelList(1);
          // toggleTourGuide();
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.ui.postLoading.getAccountFormList = false;
        });
    }
    function nextPage(event: any) {
      // console.log("NEXT PAGE >>>", event)

      state.item.paging.getAccountFormList.currentPage = event;
      getAccountFormList();
    }
    function getChannelList() {
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        // project_id: projectId,
      };
      state.ui.postLoading.getChannelList = true;
      ethitransService
        .getChannelList(payLoad)
        .then((res: any) => {
          if (res.data.data && res.data.data.length) {
            // res.data.data.forEach((item: any) => {
            console.log("ChannelList Check:>>> ", res.data.data);

            state.allChannelList = res.data.data;
            // })
          }
          // state.selectedFormForEdit = res.data ? res.data : {};
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.ui.postLoading.getChannelList = false;
          getAccountFormList();
        });
    }
    function getprojectList() {
      console.log("getprojectList WOWO:>>>");
      const payLoad = {};
      state.ui.postLoading.getprojectList = true;
      ethitransService
        .getprojectList(payLoad)
        .then((res: any) => {
          // console.log("getprojectList Check:>>> ", res.data.data.projects);
          // state.projectList = res.data.data.projects
          //   ? res.data.data.projects
          //   : [];
          state.projectList = [];
          return res.data.data.projects.filter((data: any) => {
            if (data.deleted_at == null) {
              return state.projectList.push(data);
            }
          });

          // state.selectedFormForEdit = res.data ? res.data : {};
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.ui.postLoading.getprojectList = false;
        });
    }
    function getAccountFormRetrieveByIdForSend(formId: any) {
      // console.log("Get Account form Response:>>>", formId);
      state.ui.postLoading.getAccountFormRetrieveById = true;
      ethitransService
        .accountFormRetrieveById(formId)
        .then((res: any) => {
          console.log(
            "getAccountFormRetrieveById Response Check:>>> ",
            res.data.elements
          );
          state.newFormData = res.data ? res.data : {};
          // state.selectedFormForEdit = res.data ? res.data : {};
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.ui.postLoading.getAccountFormRetrieveById = false;
        });
    }
    function getChannelByProject() {
      // console.log("Get Account form Response:>>>", formId);
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        project_id: state.filter.projectId,
      };
      state.ui.postLoading.getChannelByProject = true;
      ethitransService
        .getChannelListByProject(payLoad)
        .then((res: any) => {
          console.log("getChannelByProject Response Check:>>> ", res.data.data);
          state.channelList = res.data.data ? res.data.data : [];
          // state.selectedFormForEdit = res.data ? res.data : {};
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.ui.postLoading.getChannelByProject = false;
        });
    }
    function removeSelectedMember(item: any) {
      // console.log("check the sELCTIOn", item);
      state.invite.users = state.invite.users.filter((e: any) => e != item);
      state.data.userList.push(item);
      // console.log("AFTER DELECTION", state.invite.users);
    }
    function changeExistingMemberInForm() {
      if (state.invite.users.indexOf(state.existingMember) === -1) {
        state.invite.users.push(state.existingMember);
        // console.log(state.invite.users);
      }
      state.data.userList = state.data.userList.filter(
        (e: any) => e != state.existingMember
      );
      state.existingMember = {};
    }
    function getUserListByProjectAndChannel() {
      state.item.error.erorListSendForm = [];
      state.item.hasError = false;
      // console.log("USERLIST check for channel ID", channelId);
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        project_id: state.item.sendOption.project.uuid,
        // eslint-disable-next-line @typescript-eslint/camelcase
        channel_id: state.item.sendOption.channel.uuid,
      };
      // console.log("PAYLOAD CHECK ", payLoad);
      state.ui.isPostLoading.getUserListByProjectAndChannel = true;
      ethitransService
        .projectMemberListByChannel(payLoad)
        .then((res: any) => {
          // console.log("UserList Check:>>> ", res.data.data);
          state.userListForSendOption = res.data.data ? res.data.data : [];
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.item.error.erorListSendForm)
            .then((res: any) => {
              state.item.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.item.error.erorListSendForm.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          state.ui.isPostLoading.getUserListByProjectAndChannel = false;
        });
    }
    function getChannelByProjectForSendOption() {
      // console.log("Get Account form Response:>>>", formId);
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        project_id: state.item.sendOption.project.uuid,
      };
      state.ui.postLoading.getChannelByProjectForSendOption = true;
      ethitransService
        .getChannelListByProject(payLoad)
        .then((res: any) => {
          console.log("getChannelByProject Response Check:>>> ", res.data.data);
          state.channelListForSendOption = res.data.data ? res.data.data : [];
          // state.selectedFormForEdit = res.data ? res.data : {};
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.ui.postLoading.getChannelByProjectForSendOption = false;
        });
    }
    function hideTemplateModal() {
      state.item.error.updateValidity = [];
      state.item.hasError = false;
      $("#guestUserUpdate").modal("hide");
    }
    function clientDocumentRetrieveWorkflow(
      clientDocumentId: any,
      selectedChannelId: any
    ) {
      // alert("HHHHCHECK")
      if (!clientDocumentId || !selectedChannelId) return false;
      const payLoad = {
        id: clientDocumentId,
        // eslint-disable-next-line @typescript-eslint/camelcase
        account_form_id: selectedChannelId,
      };
      state.ui.isLoading.clientDocumentRetrieveWorkflow = true;
      ethitransService
        .clientDocumentRetrieveWorkflowLink(payLoad)
        .then((res: any) => {
          // console.log("HELLOOO");
          // console.log("clientDocumentRetrieveWorkflow :>>> ", res.data.data.docId);
          // // accountFormRetrieve(res.data.data.docId);
          // state.data.clientDocumentRetrieveWorkflowLink = res.data.data
          //   ? res.data.data
          //   : {};

          window.open(res.data.data.galleryThumbUrl, "_blank");
          // $('.nav-tabs a[href="#profile"]').tab('show')
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.ui.isLoading.clientDocumentRetrieveWorkflow = false;
        });
    }
    function clientDocumentList(edocId: any, id: any) {
      const payLoad = {
        type: "ready",
      };
      state.ui.isLoading.clientDocumentList = true;
      ethitransService
        .clientDocumentList(payLoad)
        .then((res: any) => {
          // console.log("clientDocumentList :>>> ", res.data.data);
          if (res.data.data && res.data.data.length) {
            res.data.data.forEach((item: any) => {
              item.convertedFileSize = common.formatBytes(
                parseInt(item.file_size)
              );
            });
            state.data.clientDocuments = res.data.data;
          } else {
            state.data.clientDocuments = [];
          }
          let uuId = "";
          state.data.clientDocuments.filter((data: any) => {
            if (edocId === data.edoc_id) {
              // console.log("CHECK UUID >>> ",data.uuid )
              uuId = data.uuid;
            }
          });
          console.log("CHECK UUID >>>", uuId);
          // alert()
          clientDocumentRetrieveWorkflow(uuId, id);
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.ui.isLoading.clientDocumentList = false;
        });
    }
    function setNewValidity() {
      state.item.error.updateValidity = [];
      state.item.hasError = false;
      const day = state.data.selectedDate;
      const dayWrapper = moment(day);
      const dayString = dayWrapper.format("YYYY-MM-DD");
      console.log("Selected Date >>> ", state.data.selectedGuestUser.project);
      // console.log("Selected User >>> ", state.data.selectedGuestUser)
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        user_id: state.data.selectedGuestUser.user.uuid,
        // eslint-disable-next-line @typescript-eslint/camelcase
        project_id: state.data.selectedGuestUser.project.uuid,
        // eslint-disable-next-line @typescript-eslint/camelcase
        validity_date: dayString,
      };
      // console.log("FINAL Payload", payLoad)
      state.ui.postLoading.setNewValidity = true;
      ethitransService
        .updateValidityDate(payLoad)
        .then((res: any) => {
          // console.log("Index Check >>> ", common.findIndex(state.data.guestUserList, state.data.selectedGuestUser))
          // console.log("guestUserList Response WOWO: >>> ", res.data.data);

          state.data.guestUserList[
            common.findIndex(
              state.data.guestUserList,
              state.data.selectedGuestUser
            )
          ] = res.data.data;
          // state.data.guestUserList = res.data.data ? res.data.data : [];
          // if (res.data.totalPages) {
          //   state.item.paging.setNewValidity.totalPages =
          //     res.data.totalPages;
          // }
          // if (res.data.totalDocs) {
          //   state.item.paging.setNewValidity.totalDocs =
          //     res.data.totalDocs;
          // }
          // res.data.projects;
          // getChannelList(1);
          hideTemplateModal();
          state.data.successFulMessage = "Validity Modified Successfully.";
          state.data.showSuccesCustomModal = !state.data.showSuccesCustomModal;
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.item.error.updateValidity)
            .then((res: any) => {
              state.item.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.item.error.updateValidity.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          getAccountFormList();
          state.ui.postLoading.setNewValidity = false;
        });
    }
    function selectForm(form: any) {
      state.item.error.updateValidity = [];
      state.item.hasError = false;
      state.data.selectedGuestUser = form;
      console.log("Check te Data >>> ", form);
      state.data.selectedDate = new Date(`${form.validity_date}`);
      getAccountFormList();
      $("#guestUserUpdate").modal("show");
    }

    function userFormClick() {
      state.userFormData = state.formData.elements;
      console.log("DATA CHECK:>>", state.userFormData);
      $("#responseModal").modal("show");
    }
    function getAccountFormResponseByEmailAndId(user: any, index: any) {
      // console.log('API CALL');
      state.selectedUser = user;
      // console.log("Index", state.activeUserIndex);
      state.activeUserIndex = index;
      const email = state.email;

      // console.log("CHECK the Form ID", props.formId);
      console.log("CHECK Email Id", email);
      console.log("FORM Id", state.selectedFormId);
      state.ui.isLoading.accountFormResponseByEmailAndId = true;
      ethitransService
        .accountFormResponseByEmailAndId(email, state.selectedFormId)
        .then((res: any) => {
          console.log(
            "getAccountFormResponseByEmailAndId Response Check:>>> ",
            res.data
          );
          state.item.selectedUserFormDetails = res.data ? res.data : [];
          state.currentSendUser = true;
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.ui.isLoading.accountFormResponseByEmailAndId = false;
        });
    }
    function selectedUserAccountFormResponse() {
      const dt = new Date();
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        account_form_id: state.formData._id,
        // eslint-disable-next-line @typescript-eslint/camelcase
        filled_by: {
          name: state.name,
          email: state.email,
        },
        // eslint-disable-next-line @typescript-eslint/camelcase
        created_date:
          dt.getFullYear() + "-" + (dt.getMonth() + 1) + "-" + dt.getDate(),
        elements: state.userFormData,
      };
      console.log("selectedUserAccountFormResponse PAYLOAD CHECK ", payLoad);
      state.ui.isPostLoading.selectedUserAccountFormResponse = true;
      ethitransService
        .accountformResponseSave(payLoad)
        .then((res: any) => {
          console.log(
            "selectedUserAccountFormResponse Response Check:>>> ",
            res.data
          );
          // state.ui.template.response = res.data;
          $("#responseModal").modal("hide");
          getAccountFormResponseByEmailAndId(
            state.selectedUser,
            state.activeUserIndex
          );
          // $("#formview").modal("hide");
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.ui.isPostLoading.selectedUserAccountFormResponse = false;
        });
    }
    function showFormView(form: any) {
      $("#formview").modal("show");
      state.selectedFormId = form._id;
      state.ui.isPostLoading.getAccountFormRetrieveById = true;
      ethitransService
        .accountFormRetrieveById(form._id)
        .then((res: any) => {
          state.formData = res.data ? res.data : {};
          console.log("DATA CHECK", state.formData);
          if (state.formData.sentToUsers && state.formData.sentToUsers.length) {
            getAccountFormResponseByEmailAndId(
              state.formData.sentToUsers[state.activeUserIndex],
              0
            );
          }
          // state.formData.sentToUsers
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.ui.isPostLoading.getAccountFormRetrieveById = false;
        });
    }
    function hideFormView() {
      $("#formview").modal("hide");
    }
    function showNewElementEdit(data: any, type: any) {
      const formIndex = common.findIndex(state.newFormData, data);
      console.log("Selected Form Index", formIndex);
      state.selectedElement.element = data;
      state.selectedElement.type = type;
      state.selectedElement.index = common.findIndex(state.newFormData, data);
      state.selectedElement.title = data.title;
      $("#editSelectedElementOfCreateNewForm").modal("show");
    }
    function hideEditCreateNewForm() {
      $("#editSelectedElementOfCreateNewForm").modal("hide");
    }
    function showNewTitleInCreateFormEdit(data: any) {
      // editSelectedElementOfCreateNewForm
      console.log("Data Selected >>", data);
      state.selectedElement.type = data;
      // console.log("Title Data Check: >>", state.selectedElement.type);
      // console.log("edit Title:>>", state.selectedFormForEdit.title);
      // console.log("edit Description:>>", state.selectedFormForEdit.description);
      state.selectedElement.title = state.newFormData.title;
      state.selectedElement.description = state.newFormData.description;
      $("#editSelectedElementOfCreateNewForm").modal("show");
    }
    function editSelectedForm(form: any) {
      router.push({
        name: "admin-channel-create-form",
        query: { formId: form._id, mode: "edit" },
      });
      // $(".modal-backdrop").remove();
    }
    function sendSelectedForm(form: any) {
      state.item.sendOption.project = {};
      state.item.sendOption.channel = {};
      state.existingMember = {};
      state.invite.users = [];
      state.data.additionalMessage = "";
      if (form.elements && !form.elements.length) {
        console.log("<< EDOC FORM >>");
        // state.data.selectedEdocForm = form
        $("#sendoption").modal("show");
      } else {
        router.push({
          name: "admin-channel-create-form",
          query: { formId: form._id, mode: "send" },
        });
      }
      // $(".modal-backdrop").remove();
    }
    // function getAccountFormList() {
    //   state.ui.isLoading.getAccountFormList = true;
    //   ethitransService
    //     .accountFormList()
    //     .then((res: any) => {
    //       console.log("UserList Response Check:>>> ", res.data);
    //       // state.formList = res.data ? res.data : [];
    //     })
    //     .catch((error: any) => {
    //       console.log(error);
    //     })
    //     .finally(() => {
    //       state.ui.isLoading.getAccountFormList = false;
    //     });
    // }
    function searchFilterBy() {
      // console.log("Hello");
      // getAccountFormList();
    }

     function clearFilter() {
      state.filter.projectId="",
      state.filter.channelId="",
          getAccountFormList();
    }

    function changeExistingMemberInSendOption() {
      if (state.invite.users.indexOf(state.existingMember) === -1) {
        state.invite.users.push(state.existingMember);
        // console.log(state.invite.users);
      }
      state.userList = state.userList.filter(
        (e: any) => e != state.existingMember
      );
      state.existingMember = {};
    }
    function validateSendDigitizedForm() {
      // console.log("Validate Send Digitize Form");
      state.item.error.erorListSendForm = [];
      state.item.hasError = false;

      // console.log("EMAIL CHECK", state.invite.users);

      if (state.invite.users && !state.invite.users.length) {
        state.item.error.erorListSendForm.push("Atleast 1 Email is required.");
      }

      if (
        !state.item.error.erorListSendForm &&
        !state.item.error.erorListSendForm.length
      ) {
        state.item.hasError = false;
      } else if (state.item.error.erorListSendForm.length != 0) {
        state.item.hasError = true;
      }
    }
    function sendOptionBack() {
      $("#sendoption").modal("hide");
    }
    onMounted(() => {
      // console.log("Full Name",localStorage.getItem('current_user_details'))
      // console.log("Email",localStorage.getItem('current_user_email'))
      getprojectList();
      // getChannelList();
      state.name = localStorage.getItem("current_user_details");
      state.email = localStorage.getItem("current_user_email");
      getAccountFormList();
      // toggleTourGuide();
    });
    return {
      state,
      toggleTourGuide,
      formPrivacyMessage,
      clientDocumentList,
      clientDocumentRetrieveWorkflow,
      changeExistingMemberInSendOption,
      sendOptionBack,
      removeSelectedMember,
      changeExistingMemberInForm,
      getChannelByProjectForSendOption,
      getUserListByProjectAndChannel,
      validateSendDigitizedForm,
      editSelectedForm,
      sendSelectedForm,
      userFormClick,
      selectedUserAccountFormResponse,
      getAccountFormResponseByEmailAndId,
      sentToUsers,
      showFormView,
      hideFormView,
      getChannelList,
      getChannelByProject,
      selectForm,
      getprojectList,
      showNewTitleInCreateFormEdit,
      hideEditCreateNewForm,
      showNewElementEdit,
      hideTemplateModal,
      getAccountFormRetrieveByIdForSend,
      templateList,
      getAccountFormList,
      searchFilterBy,
      nextPage,
      setNewValidity,
      guestUsers,
      closeSuccessCustomModal,
      clearFilter,
    };
  },
});
