
import { defineComponent, reactive, onMounted, computed, ref } from "vue";
import { ethitransService } from "@/services/EthitransService";
import { common } from "@/common/services/Common";
import { useRoute, useRouter } from "vue-router";
import Pusher from "pusher-js";
import EmptyData from "@/components/commonDesign/EmptyData.vue";
import { config } from "@/Config";

export default defineComponent({
    name: "ProjectWorkflowInvoiceList",
    components: {
        EmptyData,
    },
    setup(props, { emit }) {
        const router = useRouter();
        const {
            params: { projectId },
        } = useRoute();
        const isInvalid = ref(false);
        const state = reactive({
            projectId: projectId,
            selectUnitId: '' as any,
            activeUserDetails: '' as any,
            selectJobEstimationItemIndex: '' as any,
            selectItemIndex: '' as any,
            selectJobEstimationItem: '' as any,
            data: {
                subscriptionDetails: {
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    appointment_numbers: 0,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    archive_channel: 0,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    channel_file_size: 0,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    channel_message_history: 0,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    channel_per_project: 0,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    estimation_per_project: 0,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    export_channel_pdf: 0,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    files_number: 0,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    folder_number: 0,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    guests_per_project: 0,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    import_option: 0,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    invoice_payment: 0,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    member_in_smart_contract: 0,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    member_per_channel: 0,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    members_per_appointment: 0,
                    name: "Silver",
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    pdf_number: 0,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    phase_per_smart_contract: 0,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    private_channel: 0,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    project_number: 0,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    sync_option: 0,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    task_per_phase: 0,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    template_number: 0,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    total_channel_member: 0,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    total_estimation: 0,
                } as any,
                role: {} as any,
                note: '' as any,
                title: "data found",
                headings: "Invoice",
                smartContractLists: [] as any,
                unitLists: [] as any,
                details: {} as any,
                unit: {
                    name: '',
                    abbreviation: '',
                },
                filterReceivedInvoiceList: {
                    list: [] as any,
                    id: "",
                    listByStatus: 1,
                    listByTitle: "",
                },
                filterSentInvoiceList: {
                    list: [] as any,
                    id: "",
                    listByStatus: 1,
                    listByTitle: "",
                },
                isPayActive: false,
                selectedInvoice: {} as any,
            },
            ui: {
                isActive: {
                    upgradeSubscription: false,
                },
                isLoading: {
                    packageDetailsByUser: false,
                    isEdit: false,
                    getSmartContractList: false,
                    isUnitEdit: false,
                    getUnit: false,
                    pusherUpdate: false,
                    getInvoiceByPhaseOrTask: false,
                },
                isPostLoading: {
                    delete: false,
                    addUnite: false,
                    confirmPayment: false
                },
                error: {
                    errorList: {
                        createUnit: [] as any,
                        getInvoiceByPhaseOrTask: [] as any
                    },
                    validationErrorList: {
                        itemName: "",
                        unitName: "",
                    },
                    hasError: false,
                },
            },
        });
        function getProjectInvoiceList(payload: any) {
            state.ui.isLoading.getSmartContractList = true
            ethitransService
                .projectInvoiceList(payload)
                .then((res: any) => {
                    // state.data.smartContractLists = res.data.data.smartcontracts;
                    // state.data.role = res.data.data.project_role;
                    state.data.filterReceivedInvoiceList.list = res.data.data.received_invoice
                        ? res.data.data.received_invoice
                        : [];
                    state.data.filterSentInvoiceList.list = res.data.data.sent_invoices;

                })
                .catch((error: any) => {
                    console.log(error);
                })
                .finally(() => {
                    state.ui.isLoading.getSmartContractList = false;
                });
        }

        function downoadPdf(url: any) {

            window.open(url, '_blank');
        }

        function selectedInvoice(data: any) {
            state.data.selectedInvoice = {};
            state.data.selectedInvoice = data;
            let invoiceId = "";
            invoiceId = data.uuid;
            // console.log("Choose Payment Method", invoiceId);
            state.ui.error.errorList.getInvoiceByPhaseOrTask = [];
            state.ui.error.hasError = false;

            if (state.ui.isLoading.getInvoiceByPhaseOrTask) return false;

            state.ui.isLoading.getInvoiceByPhaseOrTask = true;
            ethitransService
                // .invoiceByPhaseOrTask(phaseId.toString())
                .invoiceByPhaseOrTask(invoiceId.toString(), 1)
                .then((res: any) => {
                    console.log(
                        "getInvoiceByPhaseOrTask Response Check:>>> ",
                        res.data.data
                    );
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    if (res.data.data.invoice && res.data.data.invoice.invoice_date) {
                        // eslint-disable-next-line @typescript-eslint/camelcase
                        res.data.data.invoice.invoice_date = common.localDateOnly(
                            res.data.data.invoice.invoice_date
                        );
                    }
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    if (res.data.data.new_items && res.data.data.new_items.length) {
                        console.log("New Items");
                        if (
                            res.data.data.invoice &&
                            res.data.data.invoice.invoice_items &&
                            res.data.data.invoice.invoice_items.length
                        ) {
                            console.log("invoice items");
                            res.data.data.new_items.forEach((item: any) => {
                                item.newAdded = true;
                                res.data.data.invoice.invoice_items.push(item);
                            });
                        }
                    }
                    // res.data.data.breadcrumb.reverse();
                    state.data.selectedInvoice = res.data.data.invoice
                        ? res.data.data.invoice
                        : {};
                    // state.data.breadcrumb = res.data.data.breadcrumb
                    //   ? res.data.data.breadcrumb
                    //   : [];

                    if (
                        localStorage.getItem("current_user_id") &&
                        state.data.selectedInvoice.billed_from
                    ) {
                        const userId: any = localStorage.getItem("current_user_id");
                        console.log("USER ID >>> ", userId);
                        if (
                            state.data.selectedInvoice.status === 1 ||
                            state.data.selectedInvoice.status === 4
                        ) {
                            // if (
                            //   parseInt(userId) !=
                            //   parseInt(state.data.invoiceDetails.billed_from)
                            // ) {
                            state.data.isPayActive = true;
                        } else {
                            state.data.isPayActive = false;
                        }
                    }
                    // if (routerQuery.query.account === "success") {
                    //   state.data.stripeSuccess = true;

                    //   state.data.showSuccesCustomModal = !state.data.showSuccesCustomModal;
                    //   state.data.successFulMessage = "Stripe Account Setup Completed.";
                    //   // generateInvoice();
                    // }
                    $("#selectedReceivedInvoice").modal("show");
                })
                .catch((error: any) => {
                    // console.log(error);
                    common
                        .sdCatchErr(error, state.ui.error.errorList.getInvoiceByPhaseOrTask)
                        .then((res: any) => {
                            state.ui.error.hasError = true;
                            for (const [key, value] of Object.entries(res)) {
                                if (Array.isArray(value)) {
                                    state.ui.error.errorList.getInvoiceByPhaseOrTask.push(
                                        value[0]
                                    );
                                }
                            }
                        });
                })
                .finally(() => {
                    state.ui.isLoading.getInvoiceByPhaseOrTask = false;
                });
        }

        function selectedPdf(link: any) {
            window.open(link, "_blank");
        }

        function choosePaymentMode() {
            state.ui.error.errorList.getInvoiceByPhaseOrTask = [];
            state.ui.error.hasError = false;
            if (state.ui.isPostLoading.confirmPayment) return false;

            const payLoad = {
                // eslint-disable-next-line @typescript-eslint/camelcase
                invoice_id: state.data.selectedInvoice.uuid,
                amount: state.data.selectedInvoice.total,
            };
            console.log("Paylaod Check >>> ", payLoad);
            state.ui.isPostLoading.confirmPayment = true;
            ethitransService
                .payInvoiceByStripe(payLoad)
                .then((res: any) => {
                    console.log("PAYMENT DONE >>> ", res.data.data);
                    // alert("THEN");
                    // emit("logData", res.data.data);
                    if (res.data.data.status === 1) {
                        console.log("Project Todo Response Check:>>> ", res.data.data.link);
                        window.location.replace(`${res.data.data.link}`);
                    }
                    // logData(res.data.data);
                    // hideTaskLogModal();
                })
                .catch((error: any) => {
                    // alert("CATCH WHY");
                    console.log(error);
                    common
                        .sdCatchErr(error, state.ui.error.errorList.getInvoiceByPhaseOrTask)
                        .then((res: any) => {
                            state.ui.error.hasError = true;
                            for (const [key, value] of Object.entries(res)) {
                                if (Array.isArray(value)) {
                                    state.ui.error.errorList.getInvoiceByPhaseOrTask.push(
                                        value[0]
                                    );
                                }
                            }
                        });
                })
        }

        function hideSelectedReceivedInvoice() {
            $("#selectedReceivedInvoice").modal("hide");
        }

        onMounted(() => {
            getProjectInvoiceList({ id: state.projectId });
            const userData: any = localStorage.getItem(
                "current_user_info"
            );
            state.activeUserDetails = JSON.parse(userData);
        });
        return {
            state,
            downoadPdf,
            getProjectInvoiceList,
            selectedInvoice,
            selectedPdf,
            choosePaymentMode,
            hideSelectedReceivedInvoice
        };
    },
});
