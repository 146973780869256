
import { defineComponent, reactive, onMounted, computed, watch, ref, onUnmounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import eventBus from "@/eventBus";

import { ethitransService } from "@/services/EthitransService";
import ErrorList from "@/components/commonDesign/ErrorList.vue";
// import EmptyData from "@/components/commonDesign/EmptyData.vue";

import Multiselect from 'vue-multiselect';


import ProjectWorkflowListJobEstimation from "./ProjectWorkflowListJobEstimation.vue";
import ProjectWorkflowCreateJobEstimation from "./ProjectWorkflowCreateJobEstimation.vue";
import ProjectWorkflowSendJobEstimation from "./ProjectWorkflowSendJobEstimation.vue";
import ProjectWorkflowSmartContractList from "./ProjectWorkflowSmartContractList.vue";
import ProjectWorkflowSendEstimationInvitaion from "./ProjectWorkflowSendEstimationInvitaion.vue";

import { validationService } from "@/common/ValidationService";
import { common } from "@/common/services/Common";
import { config } from "@/Config";
import Pusher from "pusher-js";
import { useStore } from "vuex";
import introJs from "intro.js";
import state from "pusher-js/types/src/core/http/state";
import { useCookie } from 'vue-cookie-next'
import { useI18n } from 'vue-i18n';

import TemplateCard from "@/components/cards/TemplateCard.vue";
import AppPhoneNumberInput from "@/components/commonDesign/AppPhoneNumberInput.vue"
import ProjectImageUploadModal from "@/components/modal/ProjectImageUploadModal.vue";
import SuccessfulModalCustom from "@/components/modal/SuccessfulModalCustom.vue";
import GoogleMapView from "@/components/commonDesign/GoogleMapView.vue";





import ProjectWorkflowUpdateJobEstimation from "./ProjectWorkflowUpdateJobEstimation.vue";
import ProjectWorkflowSmartContractPreview from "./ProjectWorkflowSmartContractPreview.vue";
import ProjectWorkflowSendSmartContract from "./ProjectWorkflowSendSmartContract.vue";
import ProjectWorkflowToDoList from "./ProjectWorkflowToDoList.vue";
import ProjectWorkflowToDoPhaseAddMember from "./ProjectWorkflowToDoPhaseAddMember.vue";
import ProjectWorkflowToDoDetails from "./ProjectWorkflowToDoDetails.vue";
import ProjectWorkflowInvoiceList from "./ProjectWorkflowInvoiceList.vue"
import ProjectWorkflowList from "./ProjectWorkflowList.vue";
import ProjectWorkflowToDoPhaseInvoice from "./ProjectWorkflowToDoPhaseInvoice.vue";
import ProjectWorkflowToDoTaskLogList from "./ProjectWorkflowToDoTaskLogList.vue";
import ProjectWorkflowEditTask from "./ProjectWorkflowEditTask.vue";
import EmptyData from "@/components/commonDesign/EmptyData.vue";

export default defineComponent({
  name: "ProjectDetailDashboard",
  props: {
    msg: String,
  },
  components: {
    EmptyData,
    ErrorList,
    Multiselect,
    TemplateCard,
    AppPhoneNumberInput,
    ProjectImageUploadModal,
    SuccessfulModalCustom,
    GoogleMapView,
    ProjectWorkflowListJobEstimation,
    ProjectWorkflowCreateJobEstimation,
    ProjectWorkflowSendJobEstimation,
    ProjectWorkflowUpdateJobEstimation,
    ProjectWorkflowSmartContractList,
    ProjectWorkflowSendEstimationInvitaion,
    ProjectWorkflowSmartContractPreview,
    ProjectWorkflowSendSmartContract,
    ProjectWorkflowToDoList,
    ProjectWorkflowToDoPhaseAddMember,
    ProjectWorkflowToDoDetails,
    ProjectWorkflowInvoiceList,
    ProjectWorkflowList,
    ProjectWorkflowToDoPhaseInvoice,
    ProjectWorkflowToDoTaskLogList,
    ProjectWorkflowEditTask
    // EmptyData,
    // 'v-select' : vSelect
  },
  setup() {
    const { t } = useI18n();
    const {
      params: { projectId },
    } = useRoute();
    const showCreateJobEstimation = ref(false);
    const showSendJobEstimation = ref(false);
    const estimationId = ref<string>('');
    const activeSidebar = ref(null);

    const options = ref([]) as any;
    const cookie = useCookie();
    const selectedUsers = ref({}) as any;
    const allUsers = ref([]) as any;
    const allChannels = ref([]) as any;
    const selectedChannels = ref({}) as any;
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const isMobile = ref(false);
    const selectedValue = ref(2) as any;
    const selectedValueForPending = ref(2) as any;
    const selectedOptions = ref([]) as any;
    const selectedDateForMember = ref('') as any;
    const latitude = ref<number>(36.0755227);  // Default latitude
    const longitude = ref<number>(-79.8203066);  // Default longitude
    const address = ref<string>('');
    const isEdit = ref<boolean>(false);
    const selectedChannelDetails = ref({
      channelGroup: {} as any,
      channel: {}
    }) as any;

    const updateLatitude = (newLatitude: number) => {
      latitude.value = newLatitude;
    };

    const updateLongitude = (newLongitude: number) => {
      longitude.value = newLongitude;
    };

    const updateAddress = (newAddress: string) => {
      address.value = newAddress;
    };
    const channelListSelected = ref({
      'label': 'Search and select ',
      'name': 'Search and select',
      'code': 'Search and select'
    }) as any;
    const state = reactive({
      activeMember: {} as any,
      selectedMember: {} as any,
      checkActivity: false,
      showCheckChannelMessage: true,
      selectedAllChannel: [] as any,
      inviteAsPublic: [] as any,
      userList: [] as any,
      existingMember: "",
      inviteMember: "",
      activePhaseId: '' as any,
      activeTaskId: '' as any,
      phaseDetails: '' as any,
      status: '' as any,
      fields: {

      } as any,
      hasShownDetails: false,
      selectUnitId: '' as any,
      selectJobEstimationItemIndex: '' as any,
      selectItemIndex: '' as any,
      selectJobEstimationItem: '' as any,
      workflowList: [] as any[],
      isLoading: {
        getUserList: false,
        getProjectDetail: false,
        getProjectRoleList: false,
      },
      postLoading: {
        getUserList: false,
        changeProjectRole: false,
        removeProjectRole: false,
        getProjectDetail: false,

      },

      data: {
        items: [
          {
            head: "Section 1",
            data: "This is the content for Section 1.",
            isOpen: false,
          },
          {
            head: "Section 2",
            data: "This is the content for Section 2.",
            isOpen: false,
          },
          {
            head: "Section 3",
            data: "This is the content for Section 3.",
            isOpen: false,
          },
        ] as any,
        roleList: [] as any,
        activeChannels: [] as any,
        allCreatedChannel: [] as any,
        selectedMemberForRemoval: {} as any,
        selectedMember: {} as any,
        selectedMemberRole: {} as any,
        projectId: "",
        title: "channels",
        heading: "Get started by creating a new channel.",
        titleProjectShare: "project shares",
        headingProjectShare: "Get started by share project with new Member.",
        allChannels: [] as any,
        channelHeadActive: false,
        projectRole: '' as any,
        showSuccesCustomModal: false,
        successFulMessage: "",
        sendChannelId: [] as any,
        selectPrivateChannel: '' as any,
        invitationMessage: "" as any,
        activeChannelAndDetails: {
          id: 1,
          currentPage: 1,
          lastPage: 1,
          perPage: 1,
        },
        selectedShareInvitationForRemoval: {} as any,
        projectShares: [] as any,
        searchBy: "",
        activeMemberList: [] as any,
        activityList: [] as any,
        invite: {
          users: [] as any[],
          invites: [] as any[],
          filteredUsers: [] as any[],
          filteredInvites: [] as any[],
        },
        createChannel: {
          name: "",
          description: "",
        },
        channelStatus: "active",
        selectedChannel: {} as any,
        channelLists: [] as any,
        coOwner: [] as any,
        filter: {
          type: "all",
          projectId: 0,
          channelId: 0,
          sortBy: -1,
          title: 1,
          selected: { name: "Newest First", id: -1 } as any,
        },
        isIcludeCoOwner: false,
        todoData: {} as any,
        projectDetail: {
          channels: [] as any,
          project: {
            id: 0,
            tags: [] as any,
          } as any,
          // eslint-disable-next-line @typescript-eslint/camelcase
          all_channels: [] as any,
          // eslint-disable-next-line @typescript-eslint/camelcase
          project_role: [] as any,
          fav: 0,
        } as any,
        activeUser: {} as any,

        pendingList: [] as any,
        channelList: [] as any,
        templateList: [] as any,
        tags: {
          mode: "tags",
          value: [] as any,
          options: [] as any,
          searchable: true,
          createTag: true,
        },
        selectedProject: {} as any,
        selectedProjectEdit: {
          projectName: "",
          projectDescription: "",
          projectTags: "",
          projectAddress: "",
        } as any,
        currentChannel: '' as any,
        isChannelCreated: false,
        subTitleMessage: "",
        channelId: '' as any,
        allProject: '' as any,
        allChannel: '' as any,
        allMember: '' as any,
        search: "" as any,
        subscriptionDetails: {
          // eslint-disable-next-line @typescript-eslint/camelcase
          appointment_numbers: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          archive_channel: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          channel_file_size: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          channel_message_history: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          channel_per_project: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          estimation_per_project: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          export_channel_pdf: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          files_number: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          folder_number: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          guests_per_project: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          import_option: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          invoice_payment: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          member_in_smart_contract: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          member_per_channel: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          members_per_appointment: 0,
          name: "Silver",
          // eslint-disable-next-line @typescript-eslint/camelcase
          pdf_number: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          phase_per_smart_contract: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          private_channel: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          project_number: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          sync_option: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          task_per_phase: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          template_number: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          total_channel_member: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          total_estimation: 0,
        } as any,
        activeUserEmail: localStorage.getItem("current_user_email"),
        privateChatChannel: [] as any,
        estimationChatChannel: [] as any,
        selectSharePhase: [] as any,
        shareProjectMember: [] as any,
        phaseChatChannel: [] as any,
        approvalChatChannel: [] as any,
        todo: {
          payerEmail: "",
        },
        projectAckowledgement: {
          payer: "",
          payers: [],
          name: "",
          description: "",
          estimatedBudget: "",
          startDate: "",
          endDate: "",
          attachments: [],
          edocLink: "",
        },
        selectedEditChannel: {
          name: "",
          description: "",
        } as any,
        selectedValidityDate: "" as any,
        channelIndex: 0,
        showProjectApprovalModal: false,
        showProjectImageUploadModal: false,
        inviteAsGuest: false,
        inviteAsPublicUser: false,
        invitationVerifyMsg: "",
        invitationProjectVerifyMsg: "",
        invitationVerify: {
          showStatus: false,
          newMemberList: [] as any,
          registeredMemberList: [] as any,
          pendingMemberList: [] as any,
        },
        invitationProjectVerify: {
          showStatus: false,
          isIndividual: false,
          newMemberList: [] as any,
          registeredMemberList: [] as any,
          pendingMemberList: [] as any,
        },
        invitationProjectEdit: {
          email: '' as any,
          showStatus: false,
          editId: '' as any,
          isIndividual: false,
          editPhase: [] as any,

        },
        selectedPendingMember: {} as any,
        imageBaseUrl: "",
        memberList: [] as any,
        selectedChannelByProject: {
          channel: {} as any,
        },
      },
      ui: {
        successMessage: '',
        isActive: {
          upgradeSubscription: false,
        },
        modal: {
          showProjectAcknowledgementModal: false,
          showEditProjectAcknowledgementModal: false,
          selectedFromChannel: false,
        },
        isLoading: {
          getProjectRoleList: false,
          channelListByProjectIdAndStatus: false,
          projectDetailsAPICalled: false,
          getProjectDetail: false,
          searchProjectChannel: false,
          projectMembersByProjectId: false,
          projectHistoryByProjectId: false,
          projectSharedToMembers: false,
        },
        isPostLoading: {
          changeProjectRole: false,
          createPrivateChannelGroup: false,
          saveEditProject: false,
          inviteAllUsers: false,
          removeShareInvitation: false,
          getAccountFormList: false,
          createChannel: false,
          modifyHistoryDateForMember: false,
          privateChat: false,
          createProjectToDo: false,
          saveEditChannel: false,
          setNewValidity: false,
          deletePendingInvitation: false,
          getChannelByProject: false,
          verifyInvitation: false,
          verifyProjectInvitation: false,
          projectMemberListByChannel: false,
          resendInvitation: false,
          channelShow: false,
          channelHide: false,
          projectFav: false,
          projectFavRemove: false,
        },
        errors: {
          errorList: {
            createPrivateChannelValidation: [] as any,
            invitation: [] as any,
            createChannel: [] as any,
            modifyHistoryDateForMember: [] as any,
            createToDo: [] as any,
            saveEditChannel: [] as any,
            errorList: [] as any,
            resendInvitation: [] as any,
            removeProjectRole: [] as any,
            projectFav: [] as any,
            projectFavRemove: [] as any,
            saveEditProject: [] as any,
          },
          hasError: false,
        },
      }
    })

    const channelList = computed(() => {
      return state.data.projectDetail.channels;

    });


    const activeTab = ref("chat");
    const activeSubTab = ref<string>("jobEstimation");
    const activeComponent = ref('listJobEstimation');


    const currentEstimationId = ref(null);
    const currentUuid = ref(null);
    const previousComponent = ref("");


    const switchTab = (tabName: string) => {
      activeTab.value = tabName;
      // if (tabName === "workflow") {
      //   activeSubTab.value = "jobEstimation";
      // }
      if (tabName === "workflow") {
        if (["owner", "co-owner", "todo_payer", "estimation_member"].includes(state.data.projectRole)) {
          activeSubTab.value = "jobEstimation";
        } else {
          activeSubTab.value = "smartContract";
          activeComponent.value = 'listSmartContract';
        }
      }
    };

    const openCreateJobEstimation = () => {
      activeComponent.value = 'createJobEstimation';
    };
    const openSendJobEstimation = (estimationId: any) => {
      currentEstimationId.value = estimationId;
      activeComponent.value = 'sendJobEstimation';
    };


    const openListJobEstimation = () => {
      activeComponent.value = 'listJobEstimation';
      currentEstimationId.value = null;
    };


    const redirectListJobEstimation = () => {
      activeSubTab.value = 'jobEstimation';
      activeComponent.value = 'listJobEstimation';
      // currentEstimationId.value = null;
    };

    const openListSmartContract = () => {
      activeComponent.value = 'listSmartContract';
      if (route.query.contractId && route.query.estimationId) {
        router.replace({
          query: {
            ...route.query,
            contractId: undefined,
            estimationId: undefined
          },
        });
      }
      // currentEstimationId.value = null;
    };

    const openEditJobEstimation = (itemId: any) => {
      currentEstimationId.value = itemId;
      activeComponent.value = "createJobEstimation";
    };

    const openUpdateJobEstimation = (itemId: any) => {
      currentEstimationId.value = itemId;
      activeComponent.value = "updateJobEstimation";
    };
    const openSmartContractPreview = (contractId: any) => {
      currentEstimationId.value = contractId;
      activeComponent.value = 'smartContractPreview';
    };
    const handleCreateEstimationInvitation = (uuid: any) => {
      currentEstimationId.value = uuid;
      activeSubTab.value = 'smartContract';
      activeComponent.value = 'sendEstimationInvitation';
    };
    const openSendSmartContract = (uuid: any, estimationId: any) => {
      currentUuid.value = uuid;
      currentEstimationId.value = estimationId;
      // currentUuid.value = estimationId;
      activeComponent.value = 'sendSmartContract';
    };

    const openToDoList = (uuid: any, back: string) => {
      currentUuid.value = uuid;
      previousComponent.value = back;
      activeSubTab.value = 'subWorkFlow';
      activeComponent.value = 'toDoList';
    };

    const openToDoTaskLogList = (phaseId: any, id: any) => {
      state.activePhaseId = phaseId;
      state.activeTaskId = id;
      activeSubTab.value = 'subWorkFlow';
      activeComponent.value = 'toDoTaskLogList';
    };

    const openToDoPhaseInvoice = (id: any) => {
      currentUuid.value = id;
      activeSubTab.value = 'invoices';
      activeComponent.value = 'toDoPhaseInvoice';
    };


    const switchWorkflowTab = (subTabName: string, uuid?: string) => {
      console.log('Switching to tab:', subTabName);
      activeSubTab.value = subTabName;
      console.log('The Active tab is:', activeSubTab.value)

      if (subTabName === "jobEstimation") {
        activeComponent.value = "listJobEstimation";
      } else if (subTabName === "smartContract") {
        activeComponent.value = "listSmartContract";
      } else if (subTabName === "invoices") {
        activeComponent.value = "invoiceList";
      }
      else if (subTabName === "subWorkFlow") {
        activeComponent.value = "workflowList";
        console.log('Tha active subTab in subWorkflow is>>>', activeComponent.value)
      }
    };


    const openToDoPhaseAddMember = (phaseId: any, taskId: any) => {
      console.log("Activating ToDoPhaseAddMember for Phase ID:", phaseId);
      activeComponent.value = "toDoPhaseAddMember";
      state.activePhaseId = phaseId;
      state.activeTaskId = taskId;
    };

    const openToDoDetails = (phaseId: any) => {
      activeComponent.value = "toDoDetails";
      console.log('The active component is>>>', activeComponent.value)
      state.activePhaseId = phaseId;
    }

    const openEditTask = (taskId: any) => {
      console.log('The Task Id is>>', taskId)
      activeComponent.value = "editTask";
      state.activeTaskId = taskId;
      console.log('The state activeTaskid is::', state.activeTaskId)
    }

    const backFromToDoList = () => {
      activeComponent.value = previousComponent.value;
      activeSubTab.value = 'smartContract';
      activeComponent.value = 'listSmartContract';
      // currentEstimationId.value = null;
    };
    const backFromToDoDetails = (projectId: any,) => {
      currentUuid.value = projectId;
      activeComponent.value = "toDoList";
    }

    const updateActiveTab = () => {
      const tab = route.query.tab;
      console.log('The tab is>>>>>>>>', tab)
      if (tab === "smartContract") {
        activeTab.value = "workflow";
        activeSubTab.value = "smartContract";
        activeComponent.value = "listSmartContract";
      }
      else if (tab === "jobEstimation") {
        activeTab.value = "workflow";
        activeSubTab.value = "jobEstimation";
        activeComponent.value = "listJobEstimation";
      }
      else if (tab === "toDoDetails") {
        activeTab.value = "workflow";
        activeSubTab.value = "subWorkFlow";
        activeComponent.value = "toDoDetails";
      }
      else if (tab === "toDoList") {
        activeTab.value = "workflow";
        activeSubTab.value = "subWorkFlow";
        activeComponent.value = "toDoList";
      }
      else if (tab === "phaseInvoice") {
        activeTab.value = "workflow";
        activeSubTab.value = 'invoices';
        activeComponent.value = 'toDoPhaseInvoice';
      }
      // router.replace({
      //   query: {
      //     ...route.query,
      //     tab: undefined,
      //   },
      //   params: {
      //     ...route.params,
      //     phaseId: undefined,
      //   },
      // });
    };

    const handleGoBack = ({ pathName, uuid }: { pathName: string; uuid: string }) => {
      if (pathName === "user-todo-list") {
        activeComponent.value = 'toDoList';
      }
      else if (pathName === "smart-contract-list") {
        activeSubTab.value = 'smartContract';
        activeComponent.value = 'listSmartContract';
      }
      else if (pathName === "workflow-list") {
        // activeSubTab.value = 'smartContract';
        activeComponent.value = 'workflowList';
      }
      else if (pathName === "user-todo-details") {
        activeSubTab.value = 'subWorkFlow';
        activeComponent.value = 'toDoDetails';
      }
    };




    function deleteByIndex(array: any, index: any) {
      if (index >= 0 && index < array.length) {
        array.splice(index, 1);
      }
    }
    const showEditChannelModal = (channel: any) => {
      console.log("Channel >>>", channel);
      state.data.selectedChannel = JSON.parse(JSON.stringify(channel));
      state.data.selectedEditChannel.name = state.data.selectedChannel.name;
      state.data.selectedEditChannel.description =
        state.data.selectedChannel.description;
      $("#editChannelModal").modal("show");
    }
    const showChannelShow = (Channel: any) => {
      state.data.channelId = Channel.id;
      $("#showChannelModal").modal("show");
    }
    const hideChannelShow = (Channel: any) => {
      state.data.channelId = Channel.id;
      $("#hideChannelModal").modal("show");
    }
    const showMemberList = (list: any) => {
      // console.log("Show List showMemberList >>> ", list)
      state.data.memberList = [];
      if (list) {
        state.data.memberList = list;
        $("#memberModal").modal("show");
      }
    }
    function hideEditChannelModal() {
      state.data.selectedChannel = {};
      $("#editChannelModal").modal("hide");
    }

    function saveEditChannel() {
      console.log("save edit channel");
      state.ui.errors.errorList.saveEditChannel = [];
      state.ui.errors.hasError = false;
      if (state.ui.isPostLoading.saveEditChannel) return false;

      const payLoad = {
        id: state.data.selectedChannel.id,
        // eslint-disable-next-line @typescript-eslint/camelcase
        project_id: state.data.projectDetail.project.id,
        name: state.data.selectedEditChannel.name,
        description: state.data.selectedEditChannel.description,
      };
      console.log("CHECK THE SAVE EDIT >>> ", payLoad);
      // memberSelected.resend = true;
      state.ui.isPostLoading.saveEditChannel = true;
      ethitransService
        .channelUpdate(payLoad)
        .then((res: any) => {
          console.log("CHECK THE CHANNEL LIST >>> ", state.data.projectDetail.channels[0].list.data)
          const index = state.data.projectDetail.channels[0].list.data.findIndex((item: any) => item.id === state.data.selectedChannel.id);
          // find the index based on id
          // state.data.selectedChannel.id
          console.log("CHECK THE CHANNEL >>> ", state.data.projectDetail.channels[0].list.data[index])
          state.data.projectDetail.channels[0].list.data[index].name = state.data.selectedEditChannel.name
          state.data.projectDetail.channels[0].list.data[index].description = state.data.selectedEditChannel.description
          // state.data.projectDetail.channels[0].list.data
          // state.channelList.forEach((item: any) => {
          //   if (item.uuid === res.data.data.uuid) {
          //     item.name = res.data.data.name;
          //     item.description = res.data.data.description;
          //   }
          // });
          const updatedChannel = res.data.data;
          const channel = state.data.projectDetail.channels.find((channel: any) =>
            channel.list?.data?.some((channelCard: any) => channelCard.uuid === updatedChannel.uuid)
          );

          if (channel) {
            const channelCard = channel.list.data.find((card: any) => card.uuid === updatedChannel.uuid);
            if (channelCard) {
              channelCard.name = updatedChannel.name;
              channelCard.description = updatedChannel.description;
            }
          }

          state.data.privateChatChannel.forEach((item: any) => {
            if (item.uuid === res.data.data.uuid) {
              item.name = res.data.data.name;
              item.description = res.data.data.description;
            }
          });
          hideEditChannelModal();
          state.data.showSuccesCustomModal = !state.data.showSuccesCustomModal;
          state.data.successFulMessage = "Channel Edited Successfully.";
          //   // state.roleList = filteredRoles ? filteredRoles : [];
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.ui.errors.errorList.saveEditChannel)
            .then((res: any) => {
              state.ui.errors.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.errors.errorList.saveEditChannel.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          // memberSelected.resend = false;
          state.ui.isPostLoading.saveEditChannel = false;
          // state.isLoading.getProjectRoleList = false;
        });
    }

    const projectMembersByProjectId = () => {
      state.ui.errors.errorList.errorList = [];
      state.ui.errors.hasError = false;
      state.ui.isLoading.projectMembersByProjectId = true;
      ethitransService
        .projectMembers(projectId)
        .then((res: any) => {
          if (res.data.data.members && res.data.data.members.length) {
            res.data.data.members.forEach((member: any) => {
              member.isChangeRole = false;
              member.convertedDateTime = common.localDateAndTime(member.added_at)
              if (state.data.projectDetail.project_role.code === 'owner' && member.email != localStorage.getItem("current_user_email") || state.data.projectDetail.project_role.code === 'co-owner' && member.email != localStorage.getItem("current_user_email")) {
                member.isChangeRole = true;
              }
            })
          }
          // common.localDateAndTime
          state.data.activeMemberList = res.data.data.members;
          // if(res.data.data.all_channels){

          // }
          // state.data.projectDetail = res.data.data ? res.data.data : {};

        })
        .catch((error: any) => {
          common
            .sdCatchErr(error, state.ui.errors.errorList.errorList)
            .then((res: any) => {
              state.ui.errors.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.errors.errorList.errorList.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          state.ui.isLoading.projectMembersByProjectId = false;
          router.replace({ query: {} });
        });
    }
    const addMember = (channelGroup: any, channel: any) => {
      console.log("Group >>> ", channelGroup)
      console.log("HELLO >>>", channel)
      if (channel && channel.id) {

        router.push({
          name: "admin-project-invite-member",
          query: { channelId: channel.id },
          // params: {channelId: channel.id },
        });
      } else {
        router.push({
          name: "admin-project-invite-member",
        });
      }
    }
    // const addMember = (channelGroup: any, channel: any) => {
    //   selectedChannelDetails.value.channelGroup = channelGroup;
    //   selectedChannelDetails.value.channel = channel;
    //   projectMembersByProjectId();
    //   if (channelGroup.list && channelGroup.list.data && channelGroup.list.data.length) {
    //     allChannels.value = channelGroup.list.data;
    //   }
    //   // Outputs the value of the 'theme' cookie

    //   state.data.showSuccesCustomModal = false;
    //   state.ui.modal.selectedFromChannel = false;
    //   if (channel && channel.uuid) {
    //     state.ui.modal.selectedFromChannel = true;
    //     state.selectedAllChannel.push({
    //       'id': channel.uuid,
    //       'label': channel.name,
    //       'name': channel.name,
    //     });
    //   } else {
    //     state.selectedAllChannel = [];
    //     state.data.projectDetail.channels.forEach((channelGroup: any) => {
    //       if (channelGroup.count) {
    //         if (channelGroup.list && channelGroup.list.data) {
    //           channelGroup.list && channelGroup.list.data.forEach((channel: any) => {
    //             allChannels.value.push(channel);
    //           })
    //         }
    //       }
    //     })
    //   }
    //   state.data.inviteAsGuest = false;
    //   state.showCheckChannelMessage = true;
    //   state.data.invitationVerify.showStatus = false;
    //   state.data.selectedChannelByProject.channel = {};
    //   state.data.invitationVerifyMsg = "";
    //   state.data.invitationVerify.newMemberList = [];
    //   state.data.invitationVerify.registeredMemberList = [];
    //   state.data.invitationVerify.pendingMemberList = [];
    //   state.inviteAsPublic = [];
    //   $("#successModal").modal("hide");
    //   state.userList = [];
    //   selectedValue.value = 2;
    //   selectedDateForMember.value = new Date();



    //   $("#inviteMember").modal("show");
    //   $("#inviteMember").modal({
    //     backdrop: "static",
    //     keyboard: true,
    //     show: true,
    //   });
    //   // state.data.invite.users.push({ email: ''}) ;
    //   state.data.invitationVerifyMsg = "";
    //   state.existingMember = "";
    //   state.inviteMember = "";
    //   state.data.invite.users = [];
    //   state.data.invite.invites = [];
    //   state.data.invite.filteredUsers = [];
    //   state.data.invite.filteredInvites = [];
    //   state.ui.errors.errorList.errorList = [];
    //   state.ui.errors.hasError = false;
    //   if (state.data.invite.invites.length === 0) {
    //     // inject the empty block
    //     state.data.invite.invites.push({
    //       'email': "",
    //       'first_name': '',
    //       'last_name': '',
    //       'phone': '',
    //       'mailNotification': false,
    //       'smsNotification': false,
    //       'mailDisable': false,
    //     });
    //   }
    //   if (channel && channel.uuid) {
    //     // console.log("Chennel >>>", channel)
    //     state.data.selectedChannelByProject.channel;
    //     state.data.selectedChannelByProject.channel = channel;
    //     // getChannelByProject(true, projectId);
    //   } else {
    //     state.data.selectedChannelByProject.channel = {};
    //     // getChannelByProject(false, projectId);
    //   }

    //   // console.log("SHOW DATA", state.data.invite.users);
    // }
    function hideAddMember() {
      state.selectedAllChannel = [];
      $("#inviteMember").modal("hide");
      state.data.inviteAsPublicUser = false;
      $("#inviteMember").modal({
        backdrop: "static",
        keyboard: true,
        show: true,
      });
    }
    function hidePrivateChannel() {
      state.data.selectPrivateChannel = '';
      allUsers.value = [];
      state.data.invite.users = [];
      state.data.invite.invites = [];
      state.ui.errors.errorList.invitation = []
      $("#createPrivateChannel").modal("hide");
    }
    function createPrivateChannelValidation() {
      // validateInviteForm();
      state.ui.errors.errorList.createPrivateChannelValidation = [];
      state.ui.errors.hasError = false;

      if (!state.data.invite.filteredUsers.length) {
        state.ui.errors.errorList.createPrivateChannelValidation.push("Invite member is required.");
      }
      if (state.inviteMember) {
        if (!validationService.isValidEmail(state.inviteMember)) {
          state.ui.errors.errorList.createPrivateChannelValidation.push("Invalid email.");
        }
      }

      // if (state.invite.invites.some((item: any) => item.first_name == '' || item.last_name == '')) {
      state.data.invite.invites.forEach((invite: any) => {
        // eslint-disable-next-line @typescript-eslint/camelcase
        if (invite.first_name == '') {
          invite.fNameError = true;
          state.ui.errors.errorList.createPrivateChannelValidation.push("First Name Last name is requred for  all invitation.");
        } else {
          invite.fNameError = false;
        }
        // eslint-disable-next-line @typescript-eslint/camelcase
        if (invite.last_name == '') {
          invite.lNameError = true;
          state.ui.errors.errorList.createPrivateChannelValidation.push("First Name Last name is requred for  all invitation.");
        } else {
          invite.lNameError = false;
        }
        if (invite.smsNotification) {
          if (invite.phone == '') {
            invite.phoneError = true;
            state.ui.errors.errorList.createPrivateChannelValidation.push("Mobile Number must be filled out to continue.");
            state.ui.errors.hasError = true;
            //   state.ui.errors.errorList.createPrivateChannelValidation.push(${`'Mobile Number must be filled out to continue for '${invite.first_name}.`});
          }
          else if (invite.phone.length != 11) {
            invite.phoneError = true;
            state.ui.errors.errorList.createPrivateChannelValidation.push("Mobile Number must be of 10 digit.");
            state.ui.errors.hasError = true;
          } else {
            invite.phoneError = false;
          }
        }
      })
      // state.ui.errors.errorList.createPrivateChannelValidation.push("First Name Last name is requred for  all invitation.");
      // }

      if (!state.ui.errors.errorList.createPrivateChannelValidation && !state.ui.errors.errorList.createPrivateChannelValidation.length) {
        state.ui.errors.hasError = false;
      } else if (state.ui.errors.errorList.createPrivateChannelValidation.length != 0) {
        state.ui.errors.hasError = true;
      }

      // console.log("CHECK BOOLEAN", state.ui.errors.hasError);
    }

    function createPrivateChannelGroup() {
      state.data.invite.filteredUsers = [];
      state.data.invite.invites.forEach((item: any) => {
        state.data.invite.filteredUsers.push(item);
      });

      createPrivateChannelValidation();
      if (
        state.ui.errors.errorList.createPrivateChannelValidation.length != 0 ||
        state.ui.errors.hasError ||
        state.ui.isPostLoading.createPrivateChannelGroup
      )
        return false;


      state.data.invite.filteredUsers.forEach((item: any) => {
        item.phone = `1${item.phone}`;
      });
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        project_id: projectId,
        // eslint-disable-next-line @typescript-eslint/camelcase
        channel_id: state.data.selectPrivateChannel ? state.data.selectPrivateChannel.uuid : '',
        users: common.removeDuplicate(state.data.invite.filteredUsers),
      };
      // if(state.data.invite.filteredUsers.length === 0 || state.data.invite.filteredInvites.length === 0) return false;
      console.log("CHECK PAYLOAD ON API CALL", payLoad);
      state.ui.isPostLoading.createPrivateChannelGroup = true;
      ethitransService
        .createPrivateChannelGroup(payLoad)
        .then((res: any) => {
          const existingIndex = state.data.privateChatChannel.findIndex((item: any) => item.id === res.data.data.id);
          console.log('im', existingIndex)
          if (existingIndex !== -1) {
            state.data.privateChatChannel[existingIndex] = res.data.data;
          } else {
            state.data.privateChatChannel.unshift(res.data.data);
          }
          hidePrivateChannel();
          state.data.successFulMessage = t('private_channel_created_successfully');
          state.data.showSuccesCustomModal = !state.data.showSuccesCustomModal;

        })
        .catch((error: any) => {
          // console.log(error);

          common.sdCatchErr(error, state.ui.errors.errorList.createPrivateChannelValidation).then((res: any) => {
            state.ui.errors.hasError = true;
            if (res) {
              if (res.name) {
                state.ui.errors.errorList.createPrivateChannelValidation.push(res.name[0]);
              } else if (res.description) {
                state.ui.errors.errorList.createPrivateChannelValidation.push(res.description[0]);
              } else {
                console.log("CHECK for Error >>>", res);
                state.ui.errors.errorList.createPrivateChannelValidation = res.error;
              }
            }
          });
        })
        .finally(() => {
          state.ui.isPostLoading.createPrivateChannelGroup = false;
          state.data.invite.filteredUsers = [];
        });
    }
    const hideSuccessInvitationModal = () => {
      $("#successModal").modal("hide");
      cookie.removeCookie('channelList');
    }

    const showSuccessModal = () => {

      $("#successModal").modal("show");
      $("#successModal").modal({
        backdrop: "static",
        keyboard: true,
        show: true,
      });
    }
    function inviteAllUsers() {
      // if (state.data.subscriptionDetails.total_channel_member === state.userList.length) {
      //   // if (1 === state.activeMemberList.length) {

      //   state.ui.isActive.upgradeSubscription = true;
      // } else {
      // state.invite.filteredUsers = [];
      state.data.invite.filteredInvites = [];
      state.data.sendChannelId = [];
      state.selectedAllChannel.forEach((item: any) => {
        if (item.uuid) {
          state.data.sendChannelId.push(item.uuid);
        } else {
          state.data.sendChannelId.push(item.id);
        }
        // state.data.sendChannelId.push(item.id);
      });
      // state.data.invite.users.forEach((item: any) => {
      //   // console.log("CHECK email", item);
      //   state.data.invite.filteredUsers.push(item.email);
      // });
      state.data.invite.invites.forEach((user: any) => {
        const data = {
          email: user.email,
          // eslint-disable-next-line @typescript-eslint/camelcase
          first_name: user.first_name,
          // eslint-disable-next-line @typescript-eslint/camelcase
          last_name: user.last_name,
          // eslint-disable-next-line @typescript-eslint/camelcase
          phone: (user.phone.length == 10) ? "1" + user.phone : user.phone,
          mailNotification: user.mailNotification,
          smsNotification: user.smsNotification,
        };
        state.data.invite.filteredInvites.push(data);
      });
      if (state.inviteMember && state.data.invite.invites.length === 0) {
        if (validationService.isValidEmail(state.inviteMember)) {
          // console.log("CHECK THE INVITE MEMBER", state.inviteMember);
          state.data.invite.filteredInvites.push(state.inviteMember);
        }
      }
      // validateInviteForm();
      if (
        state.ui.errors.errorList.invitation.length != 0 ||
        state.ui.errors.hasError ||
        state.ui.isPostLoading.inviteAllUsers
      )
        return false;
      let payLoad: any = {};

      payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        project_id: projectId,
        channels: state.data.sendChannelId,
        // eslint-disable-next-line @typescript-eslint/camelcase
        invitation_type: "channel_invitation",
        // eslint-disable-next-line @typescript-eslint/camelcase
        user_type: state.data.inviteAsGuest ? "guest" : "",
        // users: common.removeDuplicate(state.invite.filteredUsers),
        invites: common.removeDuplicate(state.data.invite.filteredInvites),
        // eslint-disable-next-line @typescript-eslint/camelcase
        message_share: {
          type: selectedValue.value,
        }
      };
      if (selectedValue.value === "4") {
        payLoad.message_share.date = selectedDateForMember.value;
        // selectedDateForMember
      }
      // if(state.invite.filteredUsers.length === 0 || state.data.invite.filteredInvites.length === 0) return false;
      console.log("CHECK PAYLOAD ON API CALL", payLoad);
      // sendChannelId
      cookie.setCookie('channelList', state.data.sendChannelId)

      // Save the channels Selected
      // all channels
      // allChannels 
      // Selected Channels Here
      // state.selectedAllChannel


      state.ui.isPostLoading.inviteAllUsers = true;
      ethitransService
        .invitation(payLoad)
        .then((res: any) => {
          console.log("Users Invite Check:>>> ", res.data.message);
          hideAddMember();
          showSuccessModal();
          state.data.invitationVerifyMsg = "";
          state.data.invitationVerify.newMemberList = [];
          state.data.invitationVerify.registeredMemberList = [];
          state.data.invitationVerify.pendingMemberList = [];
          state.data.invitationMessage = res.data.message ? res.data.message : "";

          // set the pending member in the active Channel inside the active channel Group
          const members: any = payLoad.invites;
          state.data.projectDetail.channels
          const groupIndex = common.findIndex(state.data.projectDetail.channels, selectedChannelDetails.value.channelGroup);
          const channelIndex = common.findIndex(state.data.projectDetail.channels[groupIndex].list.data, selectedChannelDetails.value.channel);
          members.forEach((member: any) => {
            state.data.projectDetail.channels[groupIndex].list.data[channelIndex].pending_members.push(member)
          })


          console.log("Channel Group Index >>> ", groupIndex);
          console.log("Channel Index >>> ", channelIndex);
          // getProjectChannel();
        })
        .catch((error: any) => {
          //   state.ui.errors.errorList.invitation.length != 0 ||
          // state.ui.errors.hasError
          console.log(error);
        })
        .finally(() => {
          state.ui.isPostLoading.inviteAllUsers = false;
        });
      // }
    }

    const showPendingList = (channelSelected: any) => {
      // if(isUserStatus && channelSelected.status === 'Active')
      // console.log("CHANNEL INDEX >>", common.findIndex(state.data.channelList, channelSelected))
      state.data.channelIndex = common.findIndex(
        state.data.projectDetail.channels[0].list.data,
        channelSelected
      );
      const list = channelSelected.pending_members;
      state.data.pendingList = [];
      if (list) {
        list.forEach((item: any) => {
          item.resend = false;
          item.delete = false;
          item.statusCode = channelSelected.status
        });
        state.data.pendingList = list;
        $("#pendingmemberModal").modal("show");
      }
    }

    const hidePendingMemberModal = () => {
      $("#pendingmemberModal").modal("hide");
    }

    const selectPendingMemberForDeletes = (member: any) => {
      state.data.selectedPendingMember = {};
      state.data.selectedPendingMember = member;
      $("#removependingmember").modal("show");
    }
    const showResendInvitationConfirmation = (memberSelected: any) => {
      state.data.selectedPendingMember = {};
      state.data.selectedPendingMember = memberSelected;
      $("#resendinvitationpendingmember").modal("show");
    }

    const hideResendInvitationConfirmation = () => {
      $("#resendinvitationpendingmember").modal("hide");
    }

    const resendInvitation = () => {
      const memberSelected: any = state.data.selectedPendingMember;
      state.ui.errors.errorList.resendInvitation = [];
      state.ui.errors.hasError = false;
      // console.log("CHECK THE SELECTED >>> ", memberSelected);

      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        invitation_id: memberSelected.uuid,
      };
      // memberSelected.resend = true;
      state.ui.isPostLoading.resendInvitation = true;
      ethitransService
        .invitationResend(payLoad)
        .then((res: any) => {
          // console.log("invitationResend:>>> ", res.data.data);
          hideResendInvitationConfirmation();
          state.data.showSuccesCustomModal = !state.data.showSuccesCustomModal;
          state.data.successFulMessage = "Invitation Resend Successfully.";
          // state.roleList = filteredRoles ? filteredRoles : [];
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.ui.errors.errorList.resendInvitation)
            .then((res: any) => {
              state.ui.errors.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.errors.errorList.resendInvitation.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          // memberSelected.resend = false;
          state.ui.isPostLoading.resendInvitation = false;
          // state.isLoading.getProjectRoleList = false;
        });
    }




    const isUserStatus = computed(() => {
      if (
        ((state.data.projectDetail.project_role.id === 1) || (state.data.projectDetail.project_role.id === 2))) {
        return true;
      } else {
        return false;
      }
    });

    const showCreateProjectChannel = () => {
      state.ui.errors.errorList.createChannel = []
      state.ui.errors.hasError = false; 
      // if (state.data.subscriptionDetails.channel_per_project === state.data.channelList.length) {
      //   state.ui.isActive.upgradeSubscription = true;
      // } else {
      //   state.isIcludeCoOwner = false;
      //   state.coOwner = state.memberList.filter((item: any) => item.project_role.code == 'co-owner')
      //     .map((item: any) => ({ ...item, isChecked: false }));
      //   $("#inviteMember").modal("hide");
      $("#createChannelByProject").modal("show");
      // }
    }
    const hideCreateChannel = () => {
      state.data.createChannel.name = "";
      state.data.createChannel.description = "";
      $("#createChannelByProject").modal("hide");
    }

    const validateForm = () => {
      state.ui.errors.errorList.createChannel = []
      state.ui.errors.hasError = false;

      if (!state.data.createChannel.name) {
        state.ui.errors.errorList.createChannel.push("Channel name is required.");
      }
      if (!state.data.createChannel.description) {
        state.ui.errors.errorList.createChannel.push("Channel description is required.");
      }
      if (!state.ui.errors.errorList.createChannel && !state.ui.errors.errorList.createChannel.length) {
        state.ui.errors.hasError = false;
      } else if (state.ui.errors.errorList.createChannel.length != 0) {
        state.ui.errors.hasError = true;
      }
    }

    const createChannel = () => {

      validateForm();
      if (
        state.ui.errors.errorList.createChannel.length != 0 ||
        state.ui.errors.hasError ||
        state.ui.isPostLoading.createChannel
      )
        return false;
      // const userList = state.coOwner.filter((item: any) => item.isChecked).map((item: any) => item.user.id);
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        project_id: projectId,
        name: state.data.createChannel.name,
        description: state.data.createChannel.description,
        // member: state.data.isIcludeCoOwner ? userList : [],
      };
      state.ui.isPostLoading.createChannel = false;
      ethitransService
        .createChannel(payLoad)
        .then((res: any) => {
          console.log("Project List Response Check:>>> ", res.data.data);
          res.data.data.createdMessage = common.generateMessage(res.data.data.created_by, res.data.data.created_at);
          // state.data.currentChannel = res.data.data;
          if (state.data.projectDetail.channels[0].list.data && state.data.projectDetail.channels[0].list.data.length > 0) {
            state.data.projectDetail.channels[0].list.data.unshift(res.data.data);
            // its working fine

          } else {
            console.log("HI CHECK >>> ", state.data.projectDetail.channels[0].list.data);
            state.data.projectDetail.channels[0].list.data.push(res.data.data);
            state.data.projectDetail.channels[0].isActive = true;
            console.log("HI CHECK  AFTER>>> ", state.data.projectDetail.channels[0].list.data);
          }
          // [4]


          // deleteByIndex(state.data.projectDetail.channels[0].list.data, state.data.projectDetail.channels[0].list.data.length - 1)
          state.data.showSuccesCustomModal = !state.data.showSuccesCustomModal;
          state.data.successFulMessage = t('channel_created_successfully');
          state.data.subTitleMessage = t('invite_members_to_participate');
          state.data.isChannelCreated = true;
          hideCreateChannel();

        })
        .catch((error: any) => {
          // console.log(error);

          common.sdCatchErr(error, state.ui.errors.errorList.createChannel).then((res: any) => {
            state.ui.errors.hasError = true;
            if (res) {
              if (res.name) {
                state.ui.errors.errorList.createChannel.push(res.name[0]);
              } else if (res.description) {
                state.ui.errors.errorList.createChannel.push(res.description[0]);
              } else {
                console.log("CHECK for Error >>>", res);
                state.ui.errors.errorList.createChannel = res.error;
              }
            }
          });
        })
        .finally(() => {
          state.ui.isPostLoading.createChannel = false;
        });
    }
    const showTour = (dataId: any, title: any, message: any) => {
      introJs()
        .setOptions({
          steps: [
            // dashboard User Guides
            {
              tooltipClass: "Tooltipproject",
              title: title,
              element: document.getElementById(
                dataId
              ) as HTMLElement,
              intro: message,
            }
          ],
        })
        .start();
    }
    const channelsByProjectId = () => {
      state.data.channelLists = [];
      // eslint-disable-next-line @typescript-eslint/camelcase
      const project_id = projectId;
      ethitransService
        .getProjectChannel(project_id, '', state.data.channelStatus)
        .then((res: any) => {
          if (res.data.data.channels && !res.data.data.channels.length) return false;
          res.data.data.channels.forEach((channel: any) => {
            state.data.channelLists.push({
              'id': channel.id,
              'label': channel.name,
              'name': channel.name,
            })
          })
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          // state.isLoading.searchProjectChannel = false;
        });
    }
    // const getProjectChannel = () => {
    //   state.ui.isLoading.getProjectChannel = true;
    //   // eslint-disable-next-line @typescript-eslint/camelcase
    //   const project_id = projectId;
    //   ethitransService
    //     .getProjectChannel(project_id, state.data.search, state.data.channelStatus)
    //     .then((res: any) => {
    //       state.data.channelList = res.data.data.channels
    //         ? res.data.data.channels
    //         : [];
    //       // state.allCreatedChannel = res.data.data.channels
    //       //   ? res.data.data.channels
    //       //   : [];
    //     })
    //     .catch((error: any) => {
    //       console.log(error);
    //     })
    //     .finally(() => {
    //       state.ui.isLoading.getProjectChannel = false;
    //       state.ui.isLoading.searchProjectChannel = false;
    //     });
    // }


    const getAccountFormList = () => {
      state.ui.errors.errorList.errorList = [];
      state.ui.errors.hasError = true;
      const payLoad = {
        search: {
          // eslint-disable-next-line @typescript-eslint/camelcase
          project_id: projectId,
          // eslint-disable-next-line @typescript-eslint/camelcase
          form_name: state.data.searchBy,
        },
        filter: {
          type: state.data.filter.type,
          email: localStorage.getItem("current_user_email"),
        },
        // eslint-disable-next-line @typescript-eslint/camelcase
        sort_by: {
          // eslint-disable-next-line @typescript-eslint/camelcase
          created_date: "",
          // "owner.created_by.name": localStorage.getItem("current_user_details"),
        },
        paging: {
          // eslint-disable-next-line @typescript-eslint/camelcase
          per_page: 10,
          // eslint-disable-next-line @typescript-eslint/camelcase
          current_page: 1,
        },
      };
      state.ui.isPostLoading.getAccountFormList = true;
      ethitransService
        .projectAccountFormListSearch(payLoad)
        .then((res: any) => {
          state.data.templateList = res.data.docs ? res.data.docs : [];
          if (state.data.templateList && state.data.templateList.length) {
            state.data.templateList.forEach((item: any) => {
              item.convertedDateTime = common.localDateAndTime(item.created_date)
            })
          }
          state.ui.isPostLoading.getAccountFormList = false;
        })
        .catch((error: any) => {
          // console.log(error);
          // common
          //   .sdCatchErr(error, state.ui.errors.errorList.errorList)
          //   .then((res: any) => {
          //     state.ui.errors.hasError = true;
          //     for (const [key, value] of Object.entries(res)) {
          //       if (Array.isArray(value)) {
          //         state.ui.errors.errorList.errorList.push(value[0]);
          //       }
          //     }
          //   });
        })
      // .finally(() => {
      //   state.ui.isPostLoading.getAccountFormList = false;
      // });
    }
    const sendSelectedForm = (selectedForm: any) => {
      console.log("forms >>> ", selectedForm)
    }
    const injectChannelsById = (existingArray: any, targetId: any, newData: any) => {
      console.log("Check Id >>> ", targetId);
      console.log("Check New Data >>> ", newData);
      console.log("Existing Array >>> ", existingArray);

      const foundItem = existingArray.find((item: any) => item.id === targetId);

      if (foundItem) {
        // Filter out duplicates from newData
        const uniqueNewData = newData.filter((newItem: any) =>
          !foundItem.list.data.some((existingItem: any) => existingItem.id === newItem.id)
        );

        // Push only unique items
        foundItem.list.data.push(...uniqueNewData);
      } else {
        console.warn(`Item with id ${targetId} not found.`);
      }
    }
    const channelListByProjectId = (email: any) => {
      state.ui.errors.errorList.errorList = [];
      state.ui.errors.hasError = false;
      state.ui.isLoading.getProjectDetail = true;
      ethitransService
        .activeChannelsMembersByProjectId(projectId)
        .then((res: any) => {
          // state.data.channelHeadActive = false;
          // console.log("channelListByChannelGroup response >>> ", res.data.data.all_channels);
          state.data.activeChannels = res.data.data.all_channels ? res.data.data.all_channels : [];

          state.data.allCreatedChannel = state.data.activeChannels.map((channel: any) => ({
            ...channel,
            isChecked: channel.active_members.some((member: any) => member.email === email),
          }));
          console.log("ROLE >>>>> ", state.data.allCreatedChannel)

        })
        .catch((error: any) => {
          common
            .sdCatchErr(error, state.ui.errors.errorList.errorList)
            .then((res: any) => {
              state.ui.errors.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.errors.errorList.errorList.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          state.ui.isLoading.getProjectDetail = false;
        });
    }
    // channelsListByChannelId
    const channelListByChannelGroup = (selectedChannel: any) => {
      console.log("Channel Details >>> ", selectedChannel);
      const channelDetails: any = selectedChannel
      const channelId = channelDetails.id;
      // eslint-disable-next-line @typescript-eslint/camelcase
      let currentPage = channelDetails.list.current_page;
      // eslint-disable-next-line @typescript-eslint/camelcase
      const lastPage = channelDetails.list.last_page;
      const channelType = state.data.channelStatus;
      console.log("CHECK THE TYPE ::: >> ", channelType);
      if (currentPage < lastPage) {
        currentPage++
      }
      if (state.data.channelHeadActive) {
        currentPage = 1;
      }
      console.log("CHECK Next Up ::: >> ", currentPage);
      state.ui.errors.errorList.errorList = [];
      state.ui.errors.hasError = false;
      state.ui.isLoading.getProjectDetail = true;
      ethitransService
        .channelsListByChannelId(projectId, channelId, channelType, currentPage)
        .then((res: any) => {
          state.data.channelHeadActive = false;
          console.log("channelListByChannelGroup response >>> ", res.data.data.channels);
          // find the index and inject the  channel in channel's group
          if (res.data.data.channels.data) {
            res.data.data.channels.data.forEach((item: any) => {
              // item.list.data.forEach((channel: any) => {
              item.createdMessage = common.generateMessage(item.created_by, item.created_at);
              console.log("Check the items >>> ", common.generateMessage(item.created_by, item.created_at))

              // })

            })
          }
          injectChannelsById(state.data.projectDetail.channels, channelId, res.data.data.channels.data)
          const index = state.data.projectDetail.channels.findIndex((item: any) => item.id === channelId);
          // console.log("FOUND INDEX >>> ", index)
          state.data.activeChannelAndDetails.id = channelDetails.id;
          // eslint-disable-next-line @typescript-eslint/camelcase
          state.data.projectDetail.channels[index].list.current_page = res.data.data.channels.current_page;
          // eslint-disable-next-line @typescript-eslint/camelcase
          state.data.projectDetail.channels[index].list.last_page = res.data.data.channels.last_page;
          // eslint-disable-next-line @typescript-eslint/camelcase
          state.data.projectDetail.channels[index].list.per_page = res.data.data.channels.per_page;
          // state.data.projectDetail.channels[index].list = res.data.data.channels.data;
          // // eslint-disable-next-line @typescript-eslint/camelcase
          // state.data.activeChannelAndDetails.currentPage = res.data.data.channels.current_page;
          // // eslint-disable-next-line @typescript-eslint/camelcase
          // state.data.activeChannelAndDetails.lastPage = res.data.data.channels.last_page;
          // // eslint-disable-next-line @typescript-eslint/camelcase
          // state.data.activeChannelAndDetails.perPage = res.data.data.channels.per_page;
          // if(res.data.data.all_channels){
          // res.data.data.all_channels.forEach((channel: any) => {
          //   channel.label = channel.name;
          //   //   channel.push({
          //   //   'label': channel.name,
          //   // })
          // })
          // }
          // state.data.projectDetail = res.data.data ? res.data.data : {};

        })
        .catch((error: any) => {
          common
            .sdCatchErr(error, state.ui.errors.errorList.errorList)
            .then((res: any) => {
              state.ui.errors.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.errors.errorList.errorList.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          state.ui.isLoading.getProjectDetail = false;
          router.replace({ query: {} });
        });
    }

    const updateArrayState = (array: any, index: any) => {
      array.forEach((item: any, i: any) => {
        if (i !== index) {
          item.isActive = false;
          if (item.list && item.list.data) {
            item.list.data = [];
          }
        }
      });
    }
    const collapseChannel = (selectedChannel: any) => {
      console.log("CHECK THE SELCTION  >>>> ", selectedChannel);
      state.data.channelHeadActive = true;
      console.log("Selected from Collapse >>> ", selectedChannel)
      const channel: any = {
        id: selectedChannel.id,
        list: {
          // eslint-disable-next-line @typescript-eslint/camelcase
          current_page: 1,
          // eslint-disable-next-line @typescript-eslint/camelcase
          last_page: selectedChannel.count
        }
      }

      // if (selectedChannel.list.data && !selectedChannel.list.data) return false;
      const groupIndex = common.findIndex(state.data.projectDetail.channels, selectedChannel);
      console.log("INDEX PAYLOAD>>> ", groupIndex)
      // updateArrayState(state.data.projectDetail.channels, groupIndex);
      state.data.projectDetail.channels[groupIndex].list.isActive = true;
      // if (state.data.projectDetail.channels[groupIndex].list.data.length) return false;
      channelListByChannelGroup(channel);
      // }

    }
    const toggleCollapse = (index: any) => {
      // Close all other sections
      state.data.projectDetail.channels.forEach((item: any, i: any) => {
        if (i !== index) {
          item.isActive = false;
        }
      });
      state.data.projectDetail.channels[index];
      collapseChannel(state.data.projectDetail.channels[index]);

      // Toggle the clicked section
      state.data.projectDetail.channels[index].isActive = !state.data.projectDetail.channels[index].isActive;
    }



    const getProjectDetail = (channelId: any, status: any) => {
      state.ui.errors.errorList.errorList = [];
      state.ui.errors.hasError = false;
      state.ui.isLoading.getProjectDetail = true;
      ethitransService
        .projectDetailsById(projectId, channelId ? channelId : "", status)
        .then((res: any) => {
          // res.data.data.channels = res.data.data.channels.filter((channel: any) => {
          //   return channel.count !== 0 && channel.count !== "0";
          // });
          res.data.data.channels = res.data.data.channels.filter((channel: any, index: number) => {
            if (index === 0 || channel.id === 1) {
              return true; // Keep the first index or the channel with id = 1
            }
            return channel.count !== 0 && channel.count !== "0"; // Apply the filter condition to other channels
          });
          // console.log("getProjectDetail response >>> ", res.data.data.channels)
          // let admin: any = {};

          // if (
          //   res.data.data.project_role.id === 1 &&
          //   res.data.data.project_role.code === "owner"
          // ) {
          //   admin = res.data.data.project_role;
          // } else {
          //   if (
          //     res.data.data.project_role.id === 2 &&
          //     res.data.data.project_role.code === "co-owner"
          //   ) {
          //     admin = res.data.data.project_role;
          //   }
          // }

          // console.log("Role HEre >>>", admin)


          if (res.data.data.channels) {
            res.data.data.channels.forEach((item: any, index: any) => {
              item.isActive = false;
              if (item.count != 0 && item.list && item.list.data.length > 0) {
                console.log("LOOP DATA List >>> ", item.list);
                item.list.data.forEach((channel: any) => {
                  channel.createdMessage = common.generateMessage(channel.created_by, channel.created_at);
                  console.log("Check the items >>> ", common.generateMessage(channel.created_by, channel.created_at))

                })
                if (index === 0) {
                  item.isActive = true;
                }

              } else {
                item.list = {
                  data: [],
                }
              }
              // set the project's first channel's page details
              //     state.data.activeChannelAndDetails.id = channelDetails.id;
              // state.data.activeChannelAndDetails.currentPage = res.data.data.channels.current_page;
              // state.data.activeChannelAndDetails.lastPage = res.data.data.channels.last_page;
              // state.data.activeChannelAndDetails.perPage = res.data.data.channels.per_page;
            })
          }
          // created_by
          // if(res.data.data.all_channels){
          res.data.data.all_channels.forEach((channel: any) => {
            channel.label = channel.name;
          })
          // }
          // eslint-disable-next-line @typescript-eslint/camelcase
          res.data.data.project.project_role = res.data.data.project_role;
          state.data.projectDetail = res.data.data ? res.data.data : {};
          state.data.projectRole = res.data.data.project_role.code;
          state.data.projectId = res.data.data.project.id;

        })
        .catch((error: any) => {
          common
            .sdCatchErr(error, state.ui.errors.errorList.errorList)
            .then((res: any) => {
              state.ui.errors.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.errors.errorList.errorList.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          state.ui.isLoading.getProjectDetail = false;
          router.replace({ query: {} });
        });
    }
    const channelListByProjectIdAndStatus = () => {
      state.ui.errors.errorList.errorList = [];
      state.ui.errors.hasError = false;
      state.ui.isLoading.getProjectDetail = true;
      ethitransService
        .channelListByProjectIdAndStatus(projectId, state.data.channelStatus)
        .then((res: any) => {
          // state.data.projectDetail.all_channels
          res.data.data.all_channels.forEach((channel: any) => {
            channel.label = channel.name;
          })
          state.data.allChannels = res.data.data.all_channels ? res.data.data.all_channels : [];
          console.log("Channels LIST response >>> ", res.data.data)
        })
        .catch((error: any) => {
          common
            .sdCatchErr(error, state.ui.errors.errorList.errorList)
            .then((res: any) => {
              state.ui.errors.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.errors.errorList.errorList.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          state.ui.isLoading.getProjectDetail = false;
          router.replace({ query: {} });
        });
    }
    const changeSelectedChannel = (selectedChannel: any) => {
      console.log("Selected CHannel", selectedChannel)
      getProjectDetail(selectedChannel.id, state.data.channelStatus);
    }
    const changeChannelStatus = () => {
      // channelListSelected.value = [];
      // state.data.search = '';
      // state.ui.isLoading.searchProjectChannel = true;
      // channelsByProjectId();
      // getProjectChannel();
      state.data.selectedChannel = {};
      console.log("CHeck the status >>> ", state.data.channelStatus)
      getProjectDetail(state.data.selectedChannel.id, state.data.channelStatus);
      channelListByProjectIdAndStatus();
    }

    const checkMobileDevice = () => {
      const mobileMediaQuery = window.matchMedia('(max-width: 767px)');
      isMobile.value = mobileMediaQuery.matches;
    };
    const updateChannelPostion = (item: any) => {
      const payLoad = {
        channels: item,
      };
      ethitransService
        .channelReorder(payLoad)
        .then((res: any) => {
          // state.data.unitLists = res.data.data;

        })
        .catch((error: any) => {
          //
        })
        .finally(() => {
          // state.ui

        });
    }

    const orderChange = () => {
      const itemPostion = computed(() => {
        return state.data.channelList[0].list.data.map((item: any, index: any) => ({ id: item.id, index }));

      });
      updateChannelPostion(itemPostion.value);
    }
    const projectHistoryByProjectId = () => {
      state.ui.errors.errorList.errorList = [];
      state.ui.errors.hasError = false;
      state.ui.isLoading.projectHistoryByProjectId = true;
      ethitransService
        .projectHistoryByProjectId(projectId)
        .then((res: any) => {
          console.log("projectHistoryByProjectId response >>> ", res.data.data)
          // if (res.data.data.members && res.data.data.members.length) {
          //   res.data.data.members.forEach((member: any) => {
          //     member.convertedDateTime = common.localDateAndTime(member.added_at)
          //     member.email = "";
          //   })
          // }
          // // common.localDateAndTime
          state.data.activityList = res.data.data.activities;
          // if(res.data.data.all_channels){

          // }
          // state.data.projectDetail = res.data.data ? res.data.data : {};

        })
        .catch((error: any) => {
          common
            .sdCatchErr(error, state.ui.errors.errorList.errorList)
            .then((res: any) => {
              state.ui.errors.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.errors.errorList.errorList.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          state.ui.isLoading.projectHistoryByProjectId = false;
          router.replace({ query: {} });
        });
    }
    const projectSharedToMembers = () => {
      state.ui.errors.errorList.errorList = [];
      state.ui.errors.hasError = false;
      state.ui.isLoading.projectSharedToMembers = true;
      ethitransService
        .projectSharedToMembers(projectId)
        .then((res: any) => {
          console.log("projectSharedToMembers response >>> ", res.data.data)
          // if (res.data.data.members && res.data.data.members.length) {
          //   res.data.data.members.forEach((member: any) => {
          //     member.convertedDateTime = common.localDateAndTime(member.added_at)
          //     member.email = "";
          //   })
          // }
          // // common.localDateAndTime
          state.data.projectShares = res.data.data.project_shares;
          // if(res.data.data.all_channels){

          // }
          // state.data.projectDetail = res.data.data ? res.data.data : {};

        })
        .catch((error: any) => {
          common
            .sdCatchErr(error, state.ui.errors.errorList.errorList)
            .then((res: any) => {
              state.ui.errors.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.errors.errorList.errorList.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          state.ui.isLoading.projectSharedToMembers = false;
          router.replace({ query: {} });
        });
    }
    function hideShareProjectRemove() {
      $("#removeProjectShareInvitation").modal("hide");
      state.data.selectedShareInvitationForRemoval = {};
      // state.ui.errors.errorList.removeProjectRole = [];
      // state.ui.errors.hasError = false;
    }
    function selectShareProjectRemove(member: any) {
      // console.log('aler', member);
      state.ui.errors.errorList.removeProjectRole = [];
      state.ui.errors.hasError = false;
      $("#removeProjectShareInvitation").modal("show");
      state.data.selectedShareInvitationForRemoval = {};
      state.data.selectedShareInvitationForRemoval = member;
    }

    const removeShareInvitation = () => {
      state.ui.errors.errorList.removeProjectRole = [];
      state.ui.errors.hasError = false;
      if (state.ui.isPostLoading.removeShareInvitation) return false;
      state.ui.isPostLoading.removeShareInvitation = true;
      ethitransService
        .removeProjectShareInvitation(state.data.selectedShareInvitationForRemoval.id.toString())

        .then((res: any) => {
          hideShareProjectRemove();
          state.data.projectShares = res.data.data.project_share ? res.data.data.project_share : [];
          state.data.showSuccesCustomModal = !state.data.showSuccesCustomModal;
          state.data.successFulMessage = "Member Remove Successfully.";
          // hideProjectMemberRemove();
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.ui.errors.errorList.removeProjectRole)
            .then((res: any) => {
              state.ui.errors.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.errors.errorList.removeProjectRole.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          state.ui.isPostLoading.removeShareInvitation = false;
        });
    }
    const addNewMember = () => {
      if (state.ui.errors.errorList.invitation.length && state.ui.errors.hasError) {
        if (state.data.invite.invites && state.data.invite.invites.length) {
          state.data.invite.invites.forEach((item: any) => {
            if (item.first_name || item.last_name) {
              item.fNameError = false;
              item.lNameError = false;
              state.ui.errors.errorList.invitation = [];
              state.ui.errors.hasError = false;
            }

          })
        }
      }
      state.data.invite.invites.push({
        'email': "",
        'first_name': '',
        'last_name': '',
        'phone': '',
        'mailNotification': false,
        'smsNotification': false,
        'mailDisable': false,
      });
    }
    const privateChat = (member: any) => {
      console.log("Private CHAT >>>", member);
      console.log("Private UUID >>>", member.uuid);
      member.isLoading = true;
      state.ui.errors.errorList.errorList = [];
      state.ui.errors.hasError = false;
      const activeProject: any = projectId;
      if (state.ui.isPostLoading.privateChat) return false;
      member.isInProcess = true;
      state.ui.isPostLoading.privateChat = true;
      ethitransService
        .phasePrivateChannelByPhaseIdUserId("", member.uuid, activeProject)
        .then((res: any) => {
          member.isLoading = true;
          console.log("privateChat Response Check:>>> ", res.data.data);
          if (res.data.data.channel && res.data.data.channel.uuid) {
            router.push({
              name: "admin-channel",
              params: { channelId: res.data.data.channel.uuid.toString() },
            });
          }
        })
        .catch((error: any) => {
          common
            .sdCatchErr(error, state.ui.errors.errorList.errorList)
            .then((res: any) => {
              state.ui.errors.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.errors.errorList.errorList.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          state.ui.isPostLoading.privateChat = false;
        });
    }
    const createPrivateChannel = () => {
      // if (state.data.privateChatChannel.length >= state.data.subscriptionDetails.private_channel) {
      //   // if (state.data.privateChatChannel.length >= 3 ) {
      //   state.ui.isActive.upgradeSubscription = true;
      // } else {
      state.ui.errors.errorList.invitation = []
      state.data.selectPrivateChannel = '';
      allUsers.value = [];
      state.data.invite.users = [];
      state.data.invite.invites = [];
      console.log("membs List >>> ", state.data.activeMemberList)
      state.data.activeMemberList.forEach((memb: any) => {
        if (memb.email != state.data.activeUserEmail) {
          allUsers.value.push({
            'id': memb.uuid,
            'label': memb.email,
            'email': memb.email,
            'full_name': memb.full_name,
            'phone': memb.mobile_number ? memb.mobile_number : "",
          })
        }

      })
      if (state.data.invite.invites.length === 0) {
        // inject the empty block
        state.data.invite.invites.push({
          'email': "",
          'first_name': '',
          'last_name': '',
          'phone': '',
          'mailNotification': false,
          'smsNotification': false,
          'mailDisable': false,
        });
      }
      $("#createPrivateChannel").modal("show");
      // }
    }
    function handleFilteredObjects(filteredObjects: any, selectedRow: any) {
      console.log('Filtered objects:', selectedRow);
      const userSelected: any = JSON.parse(JSON.stringify(selectedRow));
      console.log('Filtered objects:', filteredObjects);
      state.data.invite.users = state.data.invite.users.filter((e: any) => e.email !== userSelected.previousEmail && e.phone !== userSelected.previousPhone);
      if (!userSelected.phone) {
        userSelected.phone = userSelected.previousPhone
      }
      if (!userSelected.email) {
        userSelected.email = userSelected.previousEmail
      }


      const row: any = JSON.parse(JSON.stringify(common.filterAndTransformUsers(state.userList, userSelected.previousPhone, userSelected.previousEmail)))
      // eslint-disable-next-line @typescript-eslint/camelcase
      row[0].full_name = row[0].first_name + " " + row[0].last_name
      row[0].email = row[0].previousEmail;
      row[0].phone = row[0].previousPhone;


      const setRow: any = {};
      setRow.label = row[0].email
      setRow.email = row[0].email
      // eslint-disable-next-line @typescript-eslint/camelcase
      setRow.full_name = row[0].full_name;
      setRow.phone = row[0].phone
      console.log("Filtered Row >>> ", setRow);
      allUsers.value.push(setRow);
      // Add your logic here to handle these objects
    }
    const checkEmptyFields = (index: any, key: any) => {
      console.log("select row while deleting")
      if (state.data.invite.invites[index][key] === "") {
        if ((!state.data.invite.invites[index].key && state.data.invite.invites[index].previousPhone) || (!state.data.invite.invites[index].key && state.data.invite.invites[index].previousEmail)) {
          let itemForDelete: any = {};
          itemForDelete = state.data.invite.invites[index];
          handleFilteredObjects(`${key} Is not available`, itemForDelete);
        }
      }
    };
    const hasValidLastContact = computed(() => {
      if (state.data.invite.invites.length === 0) {
        return false;
      }
      // state.data.invite.invites.forEach((member: any) => {
      //   // track the notify by is populated only once when information is populated
      //   member.mapNotifyByEmail = false;
      //   member.mapNotifyBySms = false;

      // })
      const members = state.data.invite.invites;

      const lastMember = members[members.length - 1];
      const hasValidEmail = lastMember?.email ? validationService.isValidEmail(lastMember.email) : false;
      const hasValidMobile = lastMember?.phone ? validationService.validateMobileNumber(lastMember.phone) : false;
      if (hasValidEmail) {
        lastMember.mailNotification = true;
        lastMember.mapNotifyByEmail = true;
      } else {
        lastMember.mailNotification = false;
      }
      if (hasValidMobile) {
        lastMember.smsNotification = true;
        lastMember.mapNotifyBySms = true;
      } else {
        lastMember.smsNotification = false;
      }


      // Return true if either the email or mobile number is valid
      return hasValidEmail || hasValidMobile;
    });
    const selectToChange = (value: any) => {
      if (value && !value.uuid) return false;
      const existingIndex = state.selectedAllChannel.some((obj: any) => obj.id === value.id);
      if (value.id == "convo101" && allChannels.value.length >= 2) {
        const filteredArray = allChannels.value.filter((item: any) => item.id !== "convo101");
        state.selectedAllChannel = state.selectedAllChannel.concat(filteredArray);
        allChannels.value = [
          {
            'id': 'convo101',
            'label': 'Select All Channels',
            'name': 'Select All Channels',
          }
        ];
      }
      else if (!existingIndex && value.id != "convo101") {
        state.selectedAllChannel.push(value);
        const filteredArray = allChannels.value.filter((item: any) => item.id !== value.id);
        allChannels.value = filteredArray;
      }
      selectedChannels.value = {};
    }
    const searchChannelRemove = () => {
      state.data.search = '';
      state.data.channelStatus = 'active';
      // channelListSelected.value = [];
      state.data.selectedChannel = {};
      // state.isLoading.searchProjectChannel = true;
      getProjectDetail("", "");
      channelListByProjectIdAndStatus();

    }

    const fetchCustomerDetails = (email: string) => {
      ethitransService.detailsByemail(email)
        .then(response => {
          console.log("DATA FETCH >>> ", response.data.data)

          const selectedCustomer: any = response.data;
          if (selectedCustomer && selectedCustomer.data) {
            const data = selectedCustomer.data;

            const customerData: any = {
              email: validationService.isValidEmail(data.email) ? data.email : null,
              phone: data.mobile_number || '',
              // eslint-disable-next-line @typescript-eslint/camelcase
              first_name: data.first_name || '',
              // eslint-disable-next-line @typescript-eslint/camelcase
              last_name: data.last_name || '',
              smsNotification: validationService.validateMobileNumber(data.mobile_number) ? true : false,
              mailNotification: validationService.isValidEmail(data.email) ? true : false,
              previousEmail: validationService.isValidEmail(data.email) ? data.email : null,
              previousPhone: data.mobile_number || '',
              // mapNotifyByEmail : true,
              // mapNotifyBySms: true,
            };

            // Find and update the existing user in the invite list
            const existingCustomerIndex = state.data.invite.users.findIndex(
              // eslint-disable-next-line @typescript-eslint/camelcase
              (customer: any) => customer.email === data.email || customer.phone === data.mobile_number
            );
            if (existingCustomerIndex !== -1) {
              // Update the user details
              state.data.invite.invites[existingCustomerIndex] = { ...customerData };
            } else {
              // Add new customer if not found
              state.data.invite.invites.push(customerData);
            }
          }
        })
        .catch(error => {
          console.error('Error fetching customer details:', error);
        });
    }
    const selectToChangeUsers = (value: any) => {
      const existingIndex = state.data.invite.users.some((obj: any) => obj.id === value.id);
      if (value.id == "convo101" && allUsers.value.length >= 2) {
        const filteredArray = allUsers.value.filter((item: any) => item.id !== "convo101");
        state.data.invite.users = state.data.invite.users.concat(filteredArray);
        allUsers.value = [
          {
            'id': 'convo101',
            'label': 'Select All users',
            'email': 'Select All users',
            'full_name': 'Select All users',
          }
        ];
      }
      else if (!existingIndex && value.id != "convo101") {
        state.data.invite.users.push(value);
        // filtered the selected
        const filteredArray = allUsers.value.filter((item: any) => item.id !== value.id);
        allUsers.value = filteredArray;
        if (value.email && value.phone) {
          fetchCustomerDetails(value.email);
        } else if (value.phone && !value.email) {
          fetchCustomerDetails(value.phone);
        } else {
          fetchCustomerDetails(value.email);
        }
      }
      selectedUsers.value = {};
    }
    const addFavProjectShow = () => {
      $("#addFavProjectModal").modal("show");
    }
    const addFavProjectClose = () => {
      $("#addFavProjectModal").modal("hide");
    }
    const projectFav = () => {
      state.ui.errors.errorList.projectFav = [];
      state.ui.errors.hasError = false;

      // validateForm();
      if (
        state.ui.errors.errorList.projectFav.length != 0 ||
        state.ui.errors.hasError ||
        state.ui.isPostLoading.projectFav
      )
        return false;
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        project_id: state.data.projectDetail.project.id,
      };
      state.ui.isPostLoading.projectFav = true;
      ethitransService
        .projectFav(payLoad)
        .then((res: any) => {
          addFavProjectClose();
          state.data.projectDetail.fav = 1;
          state.data.showSuccesCustomModal = !state.data.showSuccesCustomModal;
          state.data.successFulMessage = t('project_successfully_pinned');
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.ui.errors.errorList.projectFav)
            .then((res: any) => {
              state.ui.errors.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.errors.errorList.projectFav.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          state.ui.isPostLoading.projectFav = false;
        });
    }
    function removeFavProjectShow() {
      $("#removeFavProjectModal").modal("show");
    }
    function removeFavProjectClose() {
      $("#removeFavProjectModal").modal("hide");
    }

    function projectFavRemove() {
      state.ui.errors.errorList.projectFavRemove = [];
      state.ui.errors.hasError = false;

      // validateForm();
      if (
        state.ui.errors.errorList.projectFav.length != 0 ||
        state.ui.errors.hasError ||
        state.ui.isPostLoading.projectFavRemove
      )
        return false;
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        project_id: state.data.projectDetail.project.id,
      };
      state.ui.isPostLoading.projectFavRemove = true;
      ethitransService
        .projectFavRemove(payLoad)
        .then((res: any) => {
          removeFavProjectClose();
          state.data.projectDetail.fav = 0;
          state.data.showSuccesCustomModal = !state.data.showSuccesCustomModal;
          state.data.successFulMessage = t('project_unpinned');

        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.ui.errors.errorList.projectFavRemove)
            .then((res: any) => {
              state.ui.errors.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.errors.errorList.projectFavRemove.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          state.ui.isPostLoading.projectFavRemove = false;
        });
    }
    function closeProjectImageUploadModal() {
      state.data.showProjectImageUploadModal = !state.data.showProjectImageUploadModal;
    }
    function hideChannelClose() {
      state.data.channelId = "";
      $("#hideChannelModal").modal("hide");
    }

    const hidePendingMemberRemove = () => {
      $("#removependingmember").modal("hide");
    }

    function channelPendingMemberRemapping(
      mainArray: any,
      index: number,
      insertArray: any
    ) {
      // eslint-disable-next-line @typescript-eslint/camelcase
      mainArray[index].pending_members = insertArray;
      // eslint-disable-next-line @typescript-eslint/camelcase
      mainArray[index].pending_members_count = insertArray.length;
    }

    const deletePendingInvitation = () => {
      const member: any = state.data.selectedPendingMember;
      state.ui.errors.errorList.resendInvitation = [];
      state.ui.errors.hasError = false;
      // console.log("Delete Check >>> ", member)
      if (!member.uuid) return false;
      const payLoad = {
        uuid: member.uuid,
      };
      state.ui.isPostLoading.deletePendingInvitation = true;
      // member.delete = true;
      ethitransService
        .deletePendingInvitation(payLoad)
        .then((res: any) => {
          // state.data.showSuccesModal = !state.data.showSuccesModal;
          // state.data.successFulMessage = "Pending Member Deleted Successfully.";
          hidePendingMemberRemove();
          // console.log(
          //   "WOWO projectAccountFormListSearch Response : >>> ",
          //   res.data
          // );
          // state.templateList = res.data.docs ? res.data.docs : [];
          // member.delete = false;
          state.data.pendingList = state.data.pendingList.filter(
            (e: any) => e != member
          );
          channelPendingMemberRemapping(
            state.data.projectDetail.channels[0].list.data,
            state.data.channelIndex,
            state.data.pendingList
          );
        })
        .catch((error: any) => {
          console.log(error);
          common
            .sdCatchErr(error, state.ui.errors.errorList.resendInvitation)
            .then((res: any) => {
              state.ui.errors.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.errors.errorList.resendInvitation.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          state.ui.isPostLoading.deletePendingInvitation = false;
          // member.delete = false;
          // state.ui.postLoading.getAccountFormList = false;
        });
    }

    function channelHide() {
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        channel_id: state.data.channelId,
      };
      state.ui.isPostLoading.channelHide = true;
      ethitransService
        .channelHide(payLoad)
        .then((res: any) => {
          // state.data.projectDetail.channels[0].list.data
          const indexToRemove = state.data.projectDetail.channels[0].list.data.findIndex((item: any) => item.id === res.data.data.channel_id);
          // const removeFromOptions = state.data.projectDetail.channels[0].list.data.findIndex((item: any) => item.id === res.data.data.channel_id);
          if (indexToRemove !== -1) {
            state.data.projectDetail.channels[0].list.data.splice(indexToRemove, 1);
          }
          // if (removeFromOptions !== -1) {
          //   state.data.projectDetail.channels[0].list.data.splice(indexToRemove, 1);
          // }
          hideChannelClose();
          state.data.showSuccesCustomModal = !state.data.showSuccesCustomModal;
          state.data.successFulMessage = t('channel_successfully_hidden');
        })
        .catch((error: any) => {
          //
        })
        .finally(() => {
          state.ui.isPostLoading.channelHide = false;
        });
    }
    const showChannelClose = () => {
      state.data.channelId = "";
      $("#showChannelModal").modal("hide");
    }

    function channelShow() {
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        channel_id: state.data.channelId,
      };
      state.ui.isPostLoading.channelShow = true;
      ethitransService
        .channelShow(payLoad)
        .then((res: any) => {
          const indexToRemove = state.data.projectDetail.channels[0].list.data.findIndex((item: any) => item.id === res.data.data.channel_id);
          // const removeFromOptions = state.data.projectDetail.channels[0].list.data.findIndex((item: any) => item.id === res.data.data.channel_id);
          if (indexToRemove !== -1) {
            state.data.projectDetail.channels[0].list.data.splice(indexToRemove, 1);
          }
          showChannelClose();
          state.data.showSuccesCustomModal = !state.data.showSuccesCustomModal;
          state.data.successFulMessage = t('channel_successfully_visible');
        })
        .catch((error: any) => {
          // console.log(error);
        })
        .finally(() => {
          state.ui.isPostLoading.channelShow = false;
        });
    }
    const goToShareInvitation = () => {
      router.push({
        name: "share-invitation",
        params: { projectId: projectId },
      });
    }

    const masterTagList = () => {
      ethitransService
        .masterTagList()
        .then((res: any) => {
          res.data.data.forEach((item: any) => {
            item.label = item.name;
            item.value = item.code;
          });
          state.data.tags.options = res.data.data ? res.data.data : [];
          options.value = res.data.data ? res.data.data : [];
        })
        .catch((error: any) => {
          // common
          //   .sdCatchErr(error, state.ui.error.errorsList.masterTagList)
          //   .then((res: any) => {
          //     state.ui.error.isErrorStatus = true;
          //     for (const [key, value] of Object.entries(res)) {
          //       if (Array.isArray(value)) {
          //         state.ui.error.errorsList.masterTagList.push(value[0]);
          //       }
          //     }

          //   });
        })
        .finally(() => {
          // state.postLoading = false;
        });
    }

    const renameProjectModal = () => {
      isEdit.value = false;
      masterTagList();
      selectedOptions.value = [];
      state.data.selectedProject = JSON.parse(JSON.stringify(state.data.projectDetail.project));
      console.log("CHEK THE SELECTED PROJECT >>> ", state.data.projectDetail.tags);
      state.data.selectedProjectEdit.projectName =
        state.data.selectedProject.name;
      state.data.selectedProjectEdit.projectDescription =
        state.data.selectedProject.description;
      latitude.value = state.data.selectedProject.latitude;
      longitude.value = state.data.selectedProject.longitude;
      address.value = state.data.selectedProject.full_address;
      $("#renameModal").modal("show");
      // if(!state.data.selectedProject.tags) return false;
      state.data.projectDetail.tags.forEach((tag: any) => {
        // eslint-disable-next-line @typescript-eslint/camelcase
        selectedOptions.value.push(tag.master_tag);
      });
    }
    const checkEdit = () => {
      isEdit.value = !isEdit.value;
    }

    const saveEditProject = () => {
      state.data.tags.value = [];
      state.ui.successMessage = '';
      selectedOptions.value.forEach((tag: any) => {
        state.data.tags.value.push(tag.code)
      });
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        id: state.data.projectDetail.project.id,
        name: state.data.selectedProjectEdit.projectName,
        description: state.data.selectedProjectEdit.projectDescription,
        tags: state.data.tags.value,
        latitude: latitude.value,
        longitude: longitude.value,
        // eslint-disable-next-line @typescript-eslint/camelcase
        full_address: address.value
      };
      console.log("Payload Check >>> ", payLoad);
      state.ui.isPostLoading.saveEditProject = true;
      ethitransService
        .projectUpdate(payLoad)
        .then((res: any) => {
          isEdit.value = false;
          state.data.projectDetail.project.name = state.data.selectedProjectEdit.projectName;
          state.data.projectDetail.project.description = state.data.selectedProjectEdit.projectDescription;
          state.data.successFulMessage = t('project_details_updated_successfully');
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.ui.errors.errorList.saveEditProject)
            .then((res: any) => {
              state.ui.errors.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.errors.errorList.saveEditProject.push(value[0]);
                }
              }
            });
        })

        .finally(() => {
          state.ui.isPostLoading.saveEditProject = false;
        });
    }
    const hideRenameProjectModal = () => {
      state.data.selectedProject = {};
      state.data.tags.value = [];
      state.ui.successMessage = '';
      // getProjectDetail();
      $("#renameModal").modal("hide");

    }

    onMounted(() => {
      // channelsByProjectId();
      getProjectDetail("", "");
      channelListByProjectIdAndStatus();
      checkMobileDevice();
      eventBus.on("user-todo-add-member", openToDoPhaseAddMember);
      eventBus.on("user-todo-details", openToDoDetails);
      eventBus.on("user-todo-task-log-details", openToDoTaskLogList);
      eventBus.on("edit-task", openEditTask);
      eventBus.on("go-back", handleGoBack);
      if (route.query.tab) {
        updateActiveTab();
      }
      // open the create Chat Channel
      if (route.query.channel === "true") {
        showCreateProjectChannel()
      }
    });

    onUnmounted(() => {
      eventBus.off("user-todo-add-member", openToDoPhaseAddMember);
      eventBus.off("user-todo-details", openToDoDetails);
      eventBus.off("user-todo-task-log-details", openToDoTaskLogList);
      eventBus.off("go-back", handleGoBack);
      eventBus.off("edit-task", openEditTask);

    });
    const toggleSidebar = (sidebar: any) => {
      if (sidebar === 'sidebar-members') {
        projectMembersByProjectId()
      } else if (sidebar === 'sidebar-history') {
        projectHistoryByProjectId();
      } else if (sidebar === 'sidebar-latest-forms') {
        getAccountFormList();
      } else if (sidebar === 'sidebar-share-members') {
        projectSharedToMembers();
      }
      activeSidebar.value = activeSidebar.value === sidebar ? null : sidebar;
    };



    const closeSidebar = () => {
      activeSidebar.value = null;
    };

    // eslint-disable-next-line @typescript-eslint/camelcase
    const nameWithEmail = ({ full_name, email, phone }: any) => {
      if (email) {
        // eslint-disable-next-line @typescript-eslint/camelcase
        return full_name ? `${full_name} — [${email}]` : '';
      } else {

        // eslint-disable-next-line @typescript-eslint/camelcase
        return full_name ? `${full_name} — [${phone}]` : '';
      }
    };

    const removeSelectedChannel = (item: any) => {
      state.selectedAllChannel = state.selectedAllChannel.filter((e: any) => e != item);
      allChannels.value.push(item);
    }
    const removeSelectedMember = (item: any) => {
      // console.log("check the sELCTIOn", item);
      state.data.invite.users = state.data.invite.users.filter((e: any) => e != item);
      state.data.invite.invites = state.data.invite.invites.filter((e: any) => e.email !== item.email && e.phone !== item.phone);
      // insert if the length gets to zero

      if (state.data.invite.invites && state.data.invite.invites.length === 0) {
        state.data.invite.invites.push({
          'email': "",
          'first_name': '',
          'last_name': '',
          'phone': '',
          'mailNotification': false,
          'smsNotification': false,
          'mailDisable': false,
        });
      }
      // allUsers.value.push(item);

      const setRow: any = {};
      setRow.label = item.email
      setRow.email = item.email
      // eslint-disable-next-line @typescript-eslint/camelcase
      setRow.full_name = item.full_name = item.first_name + " " + item.last_name;
      setRow.phone = item.phone
      // allUsers.value.push(item);
      allUsers.value.push(setRow);

    }
    const removeSelectedUser = (item: any) => {
      // console.log("CHECK INVITATION >>>>", state.invite.invites.length);
      if (state.data.invite.invites.length === 0) return false;
      state.data.invite.invites = state.data.invite.invites.filter((e: any) => e != item);
      // console.log("AFTER DELECTION", state.data.invite.invites);
      // reset the invite existing member
      state.data.invite.users = state.data.invite.users.filter((e: any) => e.email !== item.email && e.phone !== item.phone);

      const setRow: any = {};
      setRow.label = item.email
      setRow.email = item.email
      // eslint-disable-next-line @typescript-eslint/camelcase
      setRow.full_name = item.full_name = item.first_name + " " + item.last_name;
      setRow.phone = item.phone
      // allUsers.value.push(item);
      allUsers.value.push(setRow);
      // allUsers.value.push(item);
    }
    const getProjectMemberRoleList = () => {
      state.ui.isLoading.getProjectRoleList = true;
      ethitransService
        .projectRoleList()
        .then((res: any) => {
          console.log("USER CHECK ", state.activeMember)
          console.log("<<<:ROLE MODIFICATION:>>> ", res.data.data);
          const filteredRoles: any = [];
          if (state.data.projectDetail.project_role.id === 1) {
            res.data.data.forEach((item: any) => {
              // if(item.level != state.data.projectDetail.project_role.id && item.level != 1){
              if(item.id === 2 || item.id === 3){

                filteredRoles.push(item);
              }
              // }
            });

            // console.log("FINAL ROLES", filteredRoles);
          } else {
            res.data.data.forEach((item: any) => {
              if (item.level != state.data.projectDetail.project_role.id && item.level != 1) {
                if(item.id === 2 || item.id === 3){
                filteredRoles.push(item);
              }
            }
            });
            // console.log("FINAL ROLES", filteredRoles);
          }
          state.data.roleList = filteredRoles ? filteredRoles : [];
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.ui.isLoading.getProjectRoleList = false;
        });
    }

    function updateActiveChannels(email: any) {
      return state.data.activeChannels.map((channel: any) => ({
        ...channel,
        isChecked: channel.active_members.some((member: any) => member.email === email),
      }));
    }

    const changeRole = (member: any) => {
      channelListByProjectId(member.email);
      getProjectMemberRoleList();
      console.log("Selected Member WOOOOO:>>>", member);
      state.data.selectedMember = {};
      // added_at
      // eslint-disable-next-line @typescript-eslint/camelcase
      member.added_at = common.localDateAndTime(member.added_at)
      state.data.selectedMember = member;
      // state.data.allCreatedChannel = state.data.activeChannels.map((item: any) => {
      //   if (item.active_members.some((data: any) => data.email === member.email)) {
      //     return { ...item, isChecked: true };
      //   } else {
      //     return { ...item, isChecked: false };
      //   }
      // });
      // console.log("ROLE >>>>> ", updateActiveChannels(member.email))

      $("#userprofile").modal("show");

      state.data.selectedMemberRole = member.role_code;
      state.data.selectedValidityDate = new Date(
        `${state.data.selectedMember.validity_date}`
      );
    }
    const changeProjectRole = () => {
      const newArray = state.data.allCreatedChannel.map((item: any) => {
        return {
          id: item.id,
          isChecked: item.isChecked
        };
      });
      if (state.postLoading.changeProjectRole || !state.data.selectedMemberRole)
        return false;
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        // project_member_id: state.data.selectedMember.id,
        project_id: projectId,
        // eslint-disable-next-line @typescript-eslint/camelcase
        project_role_id: state.data.selectedMemberRole,
        channels: newArray,
        // eslint-disable-next-line @typescript-eslint/camelcase
        user_id: state.data.selectedMember.id,

      };
      console.log("PAYLOAD >>> ", payLoad);
      state.postLoading.changeProjectRole = true;
      ethitransService
        .changeProjectRole(payLoad)
        .then((res: any) => {
          // getProjectDetail();
          // getProjectChannel();
          // getProjectMemberRoleList();
          if (res.data.data.members && res.data.data.members.length) {
            res.data.data.members.forEach((member: any) => {
              member.isChangeRole = false;
              member.convertedDateTime = common.localDateAndTime(member.added_at)
              if (state.data.projectDetail.project_role.code === 'owner' && member.email != localStorage.getItem("current_user_email") || state.data.projectDetail.project_role.code === 'co-owner' && member.email != localStorage.getItem("current_user_email")) {
                member.isChangeRole = true;
              }
            })
          }
          // common.localDateAndTime
          state.data.activeMemberList = res.data.data.members;
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          setTimeout(() => {
            if (state.checkActivity) {
              // state.selectedMember = {};
              // state.selectedMember = state.activeMemberList.find((meb: any) => {
              //   meb.id === state.selectedMember.id
              // });
              // state.data.selectedMemberRole = payLoad.project_role_id;
              // state.data.allCreatedChannel = state.channelList.map((item: any) => {
              //   if (item.active_member.some((data: any) => data.user.email === state.selectedMember.user.email)) {
              //     return { ...item, isChecked: true };
              //   } else {
              //     return { ...item, isChecked: false };
              //   }
              // });
              // state.data.selectedValidityDate = new Date(
              //   `${state.selectedMember.validity_date}`
              // );
              // state.checkActivity = false;
            } else {
      // hideChangeRole();
            }
          }, 2000);
          state.postLoading.changeProjectRole = false;
        });
    }

    function selectRole() {
      if (state.data.selectedMemberRole == 'co-owner') {
        state.data.allCreatedChannel = state.data.activeChannels.map((item: any) => ({ ...item, isChecked: true }));

      }
      if (state.data.selectedMemberRole == 'user') {
        state.data.allCreatedChannel = state.data.activeChannels.map((channel: any) => ({
          ...channel,
          isChecked: channel.active_members.some((member: any) => member.email === state.selectedMember.email),
        }));
      }
      if (state.data.selectedMemberRole == 'co-owner' || state.data.selectedMemberRole == 'user') {
        state.checkActivity = true;
        changeProjectRole();
      }
    }
    const changeChannelCheckBox = (channel: any) => {
      if (state.data.selectedMemberRole == 'co-owner' || state.data.selectedMemberRole == 'user') {
        channel.isChecked = !channel.isChecked;
        state.checkActivity = true;
        changeProjectRole();
        // channelListByProjectId(state.data.selectedMember.email);
      }
    }
    const hideUserProfile = () => {
      $("#userprofile").modal("hide");
    }

    const hideProjectMemberRemove = () => {
      $("#removemember").modal("hide");
      state.data.selectedMemberForRemoval = {};
      state.ui.errors.errorList.removeProjectRole = [];
      state.ui.errors.hasError = false;
    }

    const selectForMemberRemove = (member: any) => {
      console.log("CHECK THE ROLE SELECTED >>>", member)
      state.ui.errors.errorList.removeProjectRole = [];
      state.ui.errors.hasError = false;
      $("#removemember").modal("show");
      // console.log("Member Details:>> ", member);
      state.data.selectedMemberForRemoval = {};
      state.data.selectedMemberForRemoval = member;
    }
    const removeProjectRole = () => {
      state.ui.errors.errorList.removeProjectRole = [];
      state.ui.errors.hasError = false;
      if (state.postLoading.removeProjectRole) return false;

      const payLoad = {
        id: state.data.selectedMemberForRemoval.id,
      };
      state.postLoading.removeProjectRole = true;
      ethitransService
        .removeProjectMember(payLoad)
        .then((res: any) => {
          console.log("CHECK THE DATA >>> ",)

          // state.data.activeMemberList = res.data.data ? res.data.data : [];
          state.data.activeMemberList = state.data.activeMemberList.filter((item: any) => item.id !== state.data.selectedMemberForRemoval.id);
          hideProjectMemberRemove();
          // getProjectDetail();
          state.data.showSuccesCustomModal = !state.data.showSuccesCustomModal;
          state.data.successFulMessage = "Member Remove Successfully.";
          // hideProjectMemberRemove();
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.ui.errors.errorList.removeProjectRole)
            .then((res: any) => {
              state.ui.errors.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.errors.errorList.removeProjectRole.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          state.postLoading.removeProjectRole = false;
        });
    }

    const goToShareInvitationEdit = (id: any) => {
      console.log("CHECK THE ID >>> ", id)
      router.push({
        name: "share-invitation-edit",
        params: { editId: id },
      });
    }
    const shareProjectInvitationDetails = (uuid: any) => {
      router.push({
        name: "invite-share-project-details",
        params: { invitationId: uuid },
      });
    }



    return {
      state,
      channelList,
      toggleCollapse,
      showChannelClose,
      selectRole,
      changeProjectRole,
      changeChannelCheckBox,
      updateActiveChannels,
      channelListByProjectId,
      shareProjectInvitationDetails,
      goToShareInvitationEdit,
      removeProjectRole,
      hideProjectMemberRemove,
      selectForMemberRemove,
      hideUserProfile,
      getProjectMemberRoleList,
      changeRole,
      channelListByProjectIdAndStatus,
      removeSelectedMember,
      removeSelectedChannel,
      nameWithEmail,
      createPrivateChannelValidation,
      createPrivateChannelGroup,
      toggleSidebar,
      closeSidebar,
      activeSidebar,
      updateArrayState,
      selectedChannelDetails,
      deletePendingInvitation,
      channelPendingMemberRemapping,
      hidePendingMemberRemove,
      resendInvitation,
      hideResendInvitationConfirmation,
      showResendInvitationConfirmation,
      selectPendingMemberForDeletes,
      hidePendingMemberModal,
      activeSubTab,
      switchWorkflowTab,
      projectId,
      estimationId,
      activeComponent,
      currentEstimationId,
      currentUuid,
      openCreateJobEstimation,
      openSendJobEstimation,
      openListJobEstimation,
      openEditJobEstimation,
      openUpdateJobEstimation,
      handleCreateEstimationInvitation,
      openListSmartContract,
      openSmartContractPreview,
      redirectListJobEstimation,
      openSendSmartContract,
      updateActiveTab,
      activeTab,
      switchTab,
      openToDoList,
      backFromToDoList,
      openToDoPhaseAddMember,
      backFromToDoDetails,
      previousComponent,
      openToDoPhaseInvoice,
      getProjectDetail,
      openToDoTaskLogList,
      openEditTask,
      checkEdit,
      hideRenameProjectModal,
      saveEditProject,
      options,
      latitude,
      longitude,
      address,
      isEdit,
      updateLatitude,
      updateLongitude,
      updateAddress,
      selectedOptions,
      renameProjectModal,
      goToShareInvitation,
      hideChannelClose,
      channelHide,
      channelShow,
      closeProjectImageUploadModal,
      saveEditChannel,
      hideEditChannelModal,
      projectFavRemove,
      removeFavProjectClose,
      removeFavProjectShow,
      projectFav,
      addFavProjectClose,
      addFavProjectShow,
      privateChat,
      showSuccessModal,
      hideSuccessInvitationModal,
      selectedUsers,
      fetchCustomerDetails,
      selectToChangeUsers,
      searchChannelRemove,
      createPrivateChannel,
      selectedChannels,
      selectToChange,
      allChannels,
      hasValidLastContact,
      handleFilteredObjects,
      checkEmptyFields,
      addNewMember,
      allUsers,
      hideAddMember,
      hidePrivateChannel,
      inviteAllUsers,
      collapseChannel,
      sendSelectedForm,
      injectChannelsById,
      deleteByIndex,
      removeShareInvitation,
      hideShareProjectRemove,
      selectShareProjectRemove,
      projectSharedToMembers,
      projectMembersByProjectId,
      channelListByChannelGroup,
      isUserStatus,
      hideCreateChannel,
      channelsByProjectId,
      showCreateProjectChannel,
      getAccountFormList,
      channelListSelected,
      changeChannelStatus,
      // getProjectChannel,
      createChannel,
      isMobile,
      checkMobileDevice,
      orderChange,
      updateChannelPostion,
      showEditChannelModal,
      showChannelShow,
      hideChannelShow,
      showMemberList,
      selectedValueForPending,
      selectedValue,
      selectedDateForMember,
      showPendingList,
      addMember,
      changeSelectedChannel,
      projectHistoryByProjectId,
    };
  },
});
