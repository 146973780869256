// src/services/indexedDBService.ts

const DB_NAME = "MessagesDB";
const STORE_NAME = "unsentMessages";
const REPLY_TABLE = 'unsentReply';

export interface Message {
  messageId: number; // Index (Primary Key)
  content: string;   // Message content
}

class IndexedDBService {
  private db: IDBDatabase | null = null;

  constructor() {
    this.initDB();
  }

  // Get the latest database version
  private async getLatestDBVersion(): Promise<number> {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open(DB_NAME);

      request.onsuccess = () => {
        const db = request.result;
        const version = db.version;
        db.close(); // Close the connection
        resolve(version);
      };

      request.onerror = () => {
        console.error("Error fetching latest DB version", request.error);
        reject(request.error);
      };
    });
  }

  // Initialize IndexedDB
  private async initDB(): Promise<void> {
    try {
      const latestVersion = await this.getLatestDBVersion();
      const request = indexedDB.open(DB_NAME, latestVersion + 1);

      request.onupgradeneeded = (event) => {
        const db = (event.target as IDBOpenDBRequest).result;
        if (!db.objectStoreNames.contains(STORE_NAME)) {
          db.createObjectStore(STORE_NAME, { keyPath: "messageId" });
        }
        if (!db.objectStoreNames.contains(REPLY_TABLE)) {
          db.createObjectStore(REPLY_TABLE, { keyPath: "messageId" });
        }
      };

      request.onsuccess = (event) => {
        this.db = (event.target as IDBOpenDBRequest).result;
        console.log("IndexedDB initialized");
      };

      request.onerror = (event) => {
        console.error("IndexedDB initialization error", (event.target as IDBOpenDBRequest).error);
      };
    } catch (error) {
      console.error("Error initializing IndexedDB", error);
    }
  }

  // Ensure the DB is ready
  private async getDB(): Promise<IDBDatabase> {
    if (this.db) return this.db;
    await this.initDB();
    return this.db!;
  }

  // Save a message (messageId as the key)
  async saveMessage(message: Message): Promise<void> {
    const db = await this.getDB();
    const transaction = db.transaction(STORE_NAME, "readwrite");
    const store = transaction.objectStore(STORE_NAME);

    return new Promise((resolve, reject) => {
      const request = store.put(message);

      request.onsuccess = () => {
        console.log(`Message saved with ID: ${message.messageId}`);
        resolve();
      };

      request.onerror = () => {
        console.error("Error saving message", request.error);
        reject(request.error);
      };
    });
  }

  // Save a message (messageId as the key)
  async saveMessageReply(message: Message): Promise<void> {
    const db = await this.getDB();
    const transaction = db.transaction(REPLY_TABLE, "readwrite");
    const store = transaction.objectStore(REPLY_TABLE);

    return new Promise((resolve, reject) => {
      const request = store.put(message);

      request.onsuccess = () => {
        console.log(`Reply saved with ID: ${message.messageId}`);
        resolve();
      };

      request.onerror = () => {
        console.error("Error saving message", request.error);
        reject(request.error);
      };
    });
  }

  // Retrieve a message by messageId
  async getMessage(messageId: number): Promise<Message | undefined> {
    const db = await this.getDB();
    const transaction = db.transaction(STORE_NAME, "readonly");
    const store = transaction.objectStore(STORE_NAME);

    return new Promise((resolve, reject) => {
      const request = store.get(messageId);

      request.onsuccess = () => {
        resolve(request.result as Message | undefined);
      };

      request.onerror = () => {
        console.error(`Error retrieving message with ID: ${messageId}`);
        reject(request.error);
      };
    });
  }

  // Retrieve all messages
  async getAllMessages(): Promise<Message[]> {
    const db = await this.getDB();
    const transaction = db.transaction(STORE_NAME, "readonly");
    const store = transaction.objectStore(STORE_NAME);

    return new Promise((resolve, reject) => {
      const request = store.getAll();

      request.onsuccess = () => {
        resolve(request.result as Message[]);
      };

      request.onerror = () => {
        console.error("Error retrieving all messages", request.error);
        reject(request.error);
      };
    });
  }

  async getAllMessageReplies(): Promise<Message[]> {
    const db = await this.getDB();
    const transaction = db.transaction(REPLY_TABLE, "readonly");
    const store = transaction.objectStore(REPLY_TABLE);

    return new Promise((resolve, reject) => {
      const request = store.getAll();

      request.onsuccess = () => {
        resolve(request.result as Message[]);
      };

      request.onerror = () => {
        console.error("Error retrieving all messages", request.error);
        reject(request.error);
      };
    });
  }


  // Delete a message by messageId
  async deleteMessage(messageId: number): Promise<void> {
    const db = await this.getDB();
    const transaction = db.transaction(STORE_NAME, "readwrite");
    const store = transaction.objectStore(STORE_NAME);

    return new Promise((resolve, reject) => {
      const request = store.delete(messageId);

      request.onsuccess = () => {
        console.log(`Message deleted with ID: ${messageId}`);
        resolve();
      };

      request.onerror = () => {
        console.error(`Error deleting message with ID: ${messageId}`);
        reject(request.error);
      };
    });
  }
  // Delete a message by messageId
  async deleteMessageReply(messageId: number): Promise<void> {
    const db = await this.getDB();
    const transaction = db.transaction(REPLY_TABLE, "readwrite");
    const store = transaction.objectStore(REPLY_TABLE);

    return new Promise((resolve, reject) => {
      const request = store.delete(messageId);

      request.onsuccess = () => {
        console.log(`Replay deleted with ID: ${messageId}`);
        resolve();
      };

      request.onerror = () => {
        console.error(`Error deleting message with ID: ${messageId}`);
        reject(request.error);
      };
    });
  }

  // Clear all messages
  async clearAllMessages(): Promise<void> {
    const db = await this.getDB();
    const transaction = db.transaction(STORE_NAME, "readwrite");
    const store = transaction.objectStore(STORE_NAME);

    return new Promise((resolve, reject) => {
      const request = store.clear();

      request.onsuccess = () => {
        console.log("All messages cleared.");
        resolve();
      };

      request.onerror = () => {
        console.error("Error clearing messages.", request.error);
        reject(request.error);
      };
    });
  }
}

export const indexedDBService = new IndexedDBService();