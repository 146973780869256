
import { defineComponent, onMounted, reactive } from "vue";

export default defineComponent({
  name: "UpgradeSubscriptionModal",
  props: {
    showModal: Boolean,
  },
  setup(props, { emit }) {
    // const router = useRouter();
    const state = reactive({
      data: {
        folderName: "",
        folderList: [] as any,
        folderTitle: "folder",
        folderHeading: "No folders created",
      },
      ui: {
        isActive: {
          customModal: false,
        },
        error: {
          hasError: false,
          errorList: {
            createNewFolder: [] as any,
            getFolderList: [] as any,
          },
        },
        isLoading: {
          getFolderList: false,
        },
        isPostLoading: {
          createNewFolder: false,
        },
      },
    });
    function closeSuccessfulModal() {
      emit("close");
      state.ui.isActive.customModal = false;
    }
    const upgradeSubscription = () => {
      closeSuccessfulModal();
    }
    onMounted(() => {
      if (props.showModal) {
        state.ui.isActive.customModal = true;
      }
      //   getFolderList();
    });

    return {
      state,
      closeSuccessfulModal,
      upgradeSubscription,
    };
  },
});
