<template>
    <div>
        <h2>Adjustable Table Columns (Excel-like)</h2>
        <table ref="resizableTable" id="resizable-table">
            <thead>
                <tr>
                    <th v-for="(column, index) in columns" :key="index">
                        {{ column }}
                        <div class="resizer" @mousedown="startResize(index, $event)"
                            @touchstart="startResize(index, $event)"></div>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(row, rowIndex) in rows" :key="rowIndex">
                    <td v-for="(cell, colIndex) in row" :key="colIndex"
                        :style="{ width: columnWidths[colIndex] + 'px' }">
                        {{ cell }}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import { ref, onMounted, onBeforeUnmount } from 'vue';

export default {
    setup() {
        const columns = ref(['Column 1', 'Column 2', 'Column 3']);
        const rows = ref([
            ['Data 1', 'Data 2', 'Data 3'],
            ['Data 4', 'Data 5', 'Data 6']
        ]);
        const columnWidths = ref([150, 150, 150]); // Initial width for each column

        let startX = 0;
        let startWidth = 0;
        let currentColumnIndex = -1;
        // Resize columns
        const resizeColumn = (event) => {
            const currentX = event.clientX || event.touches[0].clientX; // Support for touch and mouse
            const newWidth = startWidth + (currentX - startX);
            if (newWidth > 20) {
                columnWidths.value[currentColumnIndex] = newWidth;
            }
        };

        // Stop resizing
        const stopResize = () => {
            document.removeEventListener('mousemove', resizeColumn);
            document.removeEventListener('mouseup', stopResize);
            document.removeEventListener('touchmove', resizeColumn);
            document.removeEventListener('touchend', stopResize);
        };
        // Start resizing: detect both mouse and touch events
        const startResize = (index, event) => {
            currentColumnIndex = index;
            startX = event.clientX || event.touches[0].clientX; // Support for touch and mouse
            startWidth = columnWidths.value[index];

            // Add appropriate event listeners for mouse or touch
            if (event.type === 'mousedown') {
                document.addEventListener('mousemove', resizeColumn);
                document.addEventListener('mouseup', stopResize);
            } else if (event.type === 'touchstart') {
                document.addEventListener('touchmove', resizeColumn);
                document.addEventListener('touchend', stopResize);
            }
        };



        // Cleanup event listeners when component is unmounted
        onBeforeUnmount(() => {
            document.removeEventListener('mousemove', resizeColumn);
            document.removeEventListener('mouseup', stopResize);
            document.removeEventListener('touchmove', resizeColumn);
            document.removeEventListener('touchend', stopResize);
        });

        return {
            columns,
            rows,
            columnWidths,
            startResize
        };
    }
};
</script>

<style scoped>
body {
    font-family: Arial, sans-serif;
    margin: 20px;
}

table {
    border-collapse: collapse;
    width: 100%;
}

th,
td {
    border: 1px solid #ccc;
    padding: 8px;
    position: relative;
    overflow: hidden;
}

th {
    background: #f4f4f4;
}

.resizer {
    position: absolute;
    top: 0;
    right: -2px;
    width: 8px;
    cursor: col-resize;
    user-select: none;
    height: 100%;
    z-index: 1;
}

@media (max-width: 600px) {
    table {
        font-size: 12px;
        /* Make text smaller on smaller screens */
    }

    .resizer {
        width: 6px;
        /* Make the resizer smaller on small screens */
    }
}
</style>