
import { defineComponent, reactive, onMounted, computed, ref } from "vue";
import { ethitransService } from "@/services/EthitransService";
import ErrorList from "@/components/commonDesign/ErrorList.vue";
import DragAndDropZone from "@/common/DragAndDropZone.vue";
import { validationService } from "@/common/ValidationService";
import { common } from "@/common/services/Common";
import { config } from "@/Config";
import Multiselect from "vue-multiselect";
import { useRoute, useRouter, onBeforeRouteLeave } from "vue-router";
import SuccessfulModalCustom from "@/components/modal/SuccessfulModalCustom.vue";
import Editor from "@/components/mention/Editor.vue";
import SendSmartContractAddPhaseModal from "@/components/todo/SendSmartContractAddPhaseModal.vue";
import AppDeleteConfirmationModal from "@/common/AppDeleteConfirmationModal.vue";
import SignaturePadModal from "@/components/modal/SignaturePadModal.vue";
import SignatureUploadModal from "@/components/modal/SignatureUploadModal.vue";
export default defineComponent({
  name: "ProjectWorkflowSendSmartContract",
  props: {
        contractId: String,
        estimationId: String
    },
  components: {
    AppDeleteConfirmationModal,
    DragAndDropZone,
    ErrorList,
    Multiselect,
    SuccessfulModalCustom,
    SendSmartContractAddPhaseModal,
    Editor,
    SignaturePadModal,
    SignatureUploadModal
  },
  setup(props, {emit}) {
    const router = useRoute();
    const route = useRouter();
    const isInvalid = ref(false);
    const canvas: any = ref(null);
    const signaturePad: any = null;
    const state = reactive({
      data: {
        haveProfileSign: false,
        useProfileSign: false,
        showSignaturePad: false,
        showSignatureUpload: false,
        uploadSignatureFile: "" as any,
        signatureLink: "" as any,

        deleteModalMessage:
          "Are you sure ? You want to delete selected Phase. It will delete its selected MainItems.",
        isActiveEdocLink: false,
        showDeletePhaseConfirmation: false,
        selectedforDelete: {},
        cropImage: "",
        timeCount: 130,
        cropSignature: "" as any,
        newUploadImge: "" as any,
        projectId: "" as any,
        contractId: "" as any,
        estimationId: "" as any,
        showSuccesCustomModal: false,
        imageBaseUrl: "",
        successFulMessage: "",
        todayData: new Date() as any,
        removeUser: {} as any,
        sendPhases: [] as any,
        individualAcceptance: false,
        approveByAll: false,
        signatureFileName: "" as any,
        showAddPhaseModal: false,
        editMode: false,
        editFrom: "" as any,
        selectedIndex: "" as any,
        selectedTaskIndex: "" as any,
        editData: {} as any,
        uploadSignature: "" as any,
        selectedItemList: [] as any,
        estimation: {} as any,
        companyInfo: {
          // eslint-disable-next-line @typescript-eslint/camelcase
          company_name: "",
          // eslint-disable-next-line @typescript-eslint/camelcase
          company_email: "",
          // eslint-disable-next-line @typescript-eslint/camelcase
          company_phone_number: "",
          // eslint-disable-next-line @typescript-eslint/camelcase
          company_address: "",
          acknowledgement: "",
          // eslint-disable-next-line @typescript-eslint/camelcase
          legal_statement: "",
          // eslint-disable-next-line @typescript-eslint/camelcase
          logo: "",
        },
        projectEstimations: {
          customer: "",
          customersList: [] as any,
          name: "",
          description: "",
          estimatedBudget: "" as any,
          startDate: "",
          endDate: "",
          attachments: [] as any,
          edocLink: "",
        },
        allCustomer: [] as any,
        selectedCustomers: [] as any,
        sendarSignature: "" as any,
        sendarSignatureFileLink: "" as any,
        createPhase: [] as any,
        tempPhase: [] as any,
      },
      ui: {
        isLoading: {
          isCompanyEdit: false,
          projectDetailsAPICalled: false,
          enableEndDate: true,
          isEdit: false,
          loadDetailsData: false,
          signaturePadLoad: false,
          projectStartDate: false,
          projectEndDate: false,
          isReadyToSend: false,
          getSmartContractDetails: false,
        },
        isPostLoading: {
          removeSelectedImage: false,
          sendEstimation: false,
          saveEstimation: false,
          delete: false,
          updateEstimation: false,
          checkSignature: false,
          createCompanyIfno: false,
        },
        errors: {
          errorList: {
            approval: [] as any,
            createCompanyIfno: [] as any,
          },
          hasError: false,
        },
      },
    });
    const acknowledgement = computed({
      get() {
        return state.data.estimation.acknowledgement || state.data.companyInfo.acknowledgement;
      },
      set(value: any) {
        state.data.estimation.acknowledgement = value;
      }
    });

    const legalStatement = computed({
      get() {
        return state.data.estimation.legal_statement || state.data.companyInfo.legal_statement;
      },
      set(value: any) {
        // eslint-disable-next-line @typescript-eslint/camelcase
        state.data.estimation.legal_statement = value;
      }
    });
    function showSignaturePad() {
      state.data.showSignaturePad = !state.data.showSignaturePad;
    }

    function hideSignaturePad() {
      state.data.showSignaturePad = !state.data.showSignaturePad;
      $("#signaturePadModal").modal("hide");
    }

    function hideSignatureUpload() {
      state.data.showSignatureUpload = false;
      $("#signatureUploadModal").modal("hide");
    }
    function uploadSignature(data: any) {
      state.data.uploadSignature = data;
      state.data.cropSignature = data;
      state.data.useProfileSign = false;
      state.data.signatureLink = "";

    }

    function uploadSignatureFile(event: any) {
      state.data.showSignatureUpload = true;
      state.data.uploadSignatureFile = event.target.files || event.dataTransfer.files;
    }

    function setLocalStorageCompanyProfile() {
      const userInfo: any = localStorage.getItem("current_user_info");
      userInfo.company = {
        acknowledgement: state.data.companyInfo.acknowledgement,
        // eslint-disable-next-line @typescript-eslint/camelcase
        company_address: state.data.companyInfo.company_address
          ? state.data.companyInfo.company_address
          : "",
        // eslint-disable-next-line @typescript-eslint/camelcase
        company_email: state.data.companyInfo.company_email,
        // eslint-disable-next-line @typescript-eslint/camelcase
        company_name: state.data.companyInfo.company_name,
        // eslint-disable-next-line @typescript-eslint/camelcase
        company_phone_number: state.data.companyInfo.company_phone_number,
        // eslint-disable-next-line @typescript-eslint/camelcase
        legal_statement: state.data.companyInfo.legal_statement,
        logo: state.data.companyInfo.logo,
      };
      localStorage.removeItem("current_user_info");
      // localStorage.setItem("current_user_info", userInfo);
      // console.log("current_user_info", localStorage.setItem("current_user_info", userInfo));
      // localStorage.setItem("current_user_info", res.data.data.full_name);
    }

    const totalSendSubItem = computed(() => {
      let subItemCount = 0;

      for (const mainItem of state.data.sendPhases) {
        subItemCount += mainItem.sub_items.length;
      }

      return subItemCount;
    });
    const estimationItemIds = computed(() => {
      return state.data.createPhase.reduce((acc: any, phase: any) => {
        if (Array.isArray(phase.child_phases) && phase.child_phases.length > 0) {
          phase.child_phases.forEach((childPhase: any) => {
            if (Array.isArray(childPhase.estimation_items) && childPhase.estimation_items.length > 0) {
              childPhase.estimation_items.forEach((item: any) => {
                acc.push(item.id);
              });
            }
          });
        }
        return acc;
      }, []);
    });

    const sendPhaseIds = computed(() => {
      return state.data.sendPhases
        .filter((phase: any) => Array.isArray(phase.sub_items) && phase.sub_items.length > 0)
        .flatMap((phase: any) => phase.sub_items.map((item: any) => item.id));
    });
    const hasValidIds = computed(() => {
      // Use reduce to flatten the arrays
      /* eslint-disable vue/no-side-effects-in-computed-properties */
      // if(state.data.createPhase && state.data.createPhase.length) return false;
      // if(!isReady.value) return false;
      // return false;
      console.log("CHECK THE PHASES IDS >>> ", estimationItemIds.value)
      const allIds: any = estimationItemIds.value;

      const mainIds = state.data.sendPhases.flatMap((phase: any) => phase.sub_items.map((item: any) => item.id));
      state.data.selectedItemList = allIds;
      console.log("CHECK THE se d >>> ", mainIds)
      return allIds.length >= mainIds.length; // You can modify the condition as needed
    });


    const isMatchingItems = computed(() => {
      const allIds = state.data.sendPhases.flatMap((phase: any) => phase.sub_items.map((item: any) => item.id));
      const selectedItems = state.data.selectedItemList;

      // Check if every selectedItem is in allIds and the lengths match
      /* eslint-enable vue/no-side-effects-in-computed-properties */
      return selectedItems.every((id: any) => allIds.includes(id)) && selectedItems.length === allIds.length;
    });
    // const isMatchingItems = computed(() => {
    //   // Flatten all IDs from state.data.sendPhases using map and concat
    //   const allIds = state.data.sendPhases.reduce((acc: any[], phase: any) => {
    //     return acc.concat(phase.sub_items.map((item: any) => item.id));
    //   }, []);

    //   // Extract all IDs from state.data.createphase using map and concat
    //   const allCreatePhaseIds = state.data.createphase.reduce((acc: any[], phase: any) => {
    //     return acc.concat(
    //       phase.groupTasks.reduce((subAcc: any[], groupTask: any) => {
    //         return subAcc.concat(groupTask.items.map((item: any) => item.id));
    //       }, [])
    //     );
    //   }, []);

    //   // Filter to get unique IDs from allCreatePhaseIds
    //   const uniqueCreatePhaseIds = allCreatePhaseIds.filter((id: any, index: number, self: any[]) =>
    //     self.indexOf(id) === index
    //   );

    //   // Check if every uniqueCreatePhaseId is in allIds and the lengths match
    //   return (
    //     uniqueCreatePhaseIds.every((id: any) => allIds.includes(id)) &&
    //     uniqueCreatePhaseIds.length === allIds.length
    //   );
    // });



    const addPhaseStatus = computed(() => {
      let activeStatus = false;
      let subItemCount = 0;

      for (const mainItem of state.data.sendPhases) {
        subItemCount += mainItem.sub_items.length;
      }

      if (subItemCount > state.data.selectedItemList.length) {
        activeStatus = true;
      } else {
        activeStatus = false;
      }
      return activeStatus;
    });

    const totalSelectedSubItem = computed(() => {
      let subItemCount = 0;

      for (const mainItem of state.data.createPhase) {
        subItemCount += mainItem.tasks.length;
      }

      return subItemCount;
    });

    function getUnselectedIds(phases: any[], selectedItems: any[]): any[] {
      return phases.flatMap(phase => phase.sub_items.map((item: any) => item.id))
        .filter(id => !selectedItems.includes(id));
    }


    function readImage(file: any) {
      const data = {} as any;
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const extension = file.name.substring(file.name.lastIndexOf(".") + 1);
        const extensionLower = extension.toLowerCase();
        data.file = e.target.result;
        data.name = file.name.replaceAll(`.${extension}`, "");
        data.extension = extensionLower;
        state.data.projectEstimations.attachments.push(data);
        console.log("CHECK DATA FOR UPLOAD>>", data);
        // state.imageList.push(data);
      };
      reader.readAsDataURL(file);
    }
    const dropFiles = (event: any) => {
      const files = event.target.files || event.dataTransfer.files;
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        readImage(file);
      }
    };
    function hideConfirmation() {
      state.data.showDeletePhaseConfirmation =
        !state.data.showDeletePhaseConfirmation;
    }

    function removeSelectedAttachment(phase: any, image: any) {
      state.data.createPhase.forEach((item: any) => {
        if (item.id === phase.id) {
          phase.attachments = phase.attachments.filter(
            (att: any) => att != image
          );
        }
      });
      state.data.projectEstimations.attachments =
        state.data.projectEstimations.attachments.filter(
          (e: any) => e != image
        );
    }
    function removeSelectedImage(image: any) {
      state.data.projectEstimations.attachments =
        state.data.projectEstimations.attachments.filter(
          (e: any) => e != image
        );
    }
    function redirectToSmartContractDashboard() {
      route.push({
        name: "smart-contract-list",
        params: { projectId: state.data.projectId },
      });
    }
    function redirectToProjectToDo() {
      // if (props.contractId) {
      //   route.push({
      //     name: "smart-contract-list",
      //     params: { projectId: state.data.projectId },
      //   });
      // } else {
      // route.push({
      //   name: "admin-project-details",
      //   params: { projectId: state.data.projectId },
      // });
      // }
      emit('back-to-smart-contract-list');
    }

    function redirectToSmartContractList() {
      // route.push({
      //   name: "smart-contract-list",
      //   params: { projectId: state.data.projectId },
      // });
      emit('back-to-smart-contract-list');
    }

    function closeSuccessCustomModal() {
      state.data.showSuccesCustomModal = !state.data.showSuccesCustomModal;
      $("#successfulModal").modal("hide");
      redirectToSmartContractList();
    }

    function addCustomerEmail(data: any) {
      isInvalid.value = false;
      const email = data;
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (email == localStorage.getItem("current_user_email")) {
        isInvalid.value = true;
        return false;
      }
      if (!emailRegex.test(email)) {
        isInvalid.value = true;
        return false;
      }
      const addData = {
        email: data,
        // eslint-disable-next-line @typescript-eslint/camelcase
        first_name: "",
        // eslint-disable-next-line @typescript-eslint/camelcase
        middle_name: "",
        // eslint-disable-next-line @typescript-eslint/camelcase
        last_name: "",
        // eslint-disable-next-line @typescript-eslint/camelcase
        address_line1: "",
        code: data,
        phone: "",
        isEdit: true,
        emailError: "",
        phoneError: "",
        mailNotification: true,
      };
      if (addData.email) {
        addData.mailNotification = true;
      }
      state.data.allCustomer.push(addData);
      state.data.selectedCustomers.push(addData);
      //  state.data.allCustomer.push(data);
    }

    function showDelete(data: any) {
      state.data.removeUser = data;
      $("#deleteModal").modal("show");
    }

    function hideDelete() {
      $("#deleteModal").modal("hide");
    }

    function isEmailValid(item: any) {
      const email = item.email;
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (email == localStorage.getItem("current_user_email")) {
        item.emailError = "Owner email  not valid here.";
        return false;
      }
      if (!emailRegex.test(email)) {
        item.emailError = "Please enter valid email.";
        return false;
      }
      item.emailError = "";
      return true;
    }

    function isPhoneValid(item: any) {
      const phone = item.phone;
      const phoneRegex = /^\(?(\d{3})\)?[-.\s]?(\d{3})[-.\s]?(\d{4})$/;
      if (!phoneRegex.test(phone)) {
        item.phoneError = "Please enter valid phone.";
        return false;
      }
      item.phoneError = "";
      return true;
    }

    function editSelectCustomer(data: any) {
      state.data.selectedCustomers[data].isEdit = true;
    }
    function updateSelectCustomer(data: any) {
      const item = state.data.selectedCustomers[data];
      item.emailError = "";
      item.phoneError = "";
      if (item.phone) {
        if (isEmailValid(item)) {
          item.emailError = "";
          item.phoneError = "";
        }
        if (isPhoneValid(item)) {
          item.emailError = "";
          item.phoneError = "";
          item.isEdit = false;
        }
      } else {
        if (isEmailValid(item)) {
          item.emailError = "";
          item.phoneError = "";
          item.isEdit = false;
        }
      }
    }
    function removeSelectCustomer(data: any) {
      state.data.selectedCustomers = state.data.selectedCustomers.filter(
        (e: any) => e != data
      );
      hideDelete();
    }

    function changeValue(data: any) {
      console.log("HERE TESSTING ", data)
      data.mailNotification = true;
      isInvalid.value = false;
    }

    function removeUploadSelectedImage(image: any) {
      state.ui.errors.hasError = false;
      if (state.ui.isPostLoading.removeSelectedImage) return false;
      state.ui.isPostLoading.removeSelectedImage = true;
      ethitransService
        .projectDocumentDelete(image.uuid.toString())
        .then((res: any) => {
          state.data.projectEstimations.attachments =
            state.data.projectEstimations.attachments.filter(
              (e: any) => e != image
            );
        })
        .catch((error: any) => {
          common
            .sdCatchErr(error, state.ui.errors.errorList.approval)
            .then((res: any) => {
              state.ui.errors.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.errors.errorList.approval.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          state.ui.isPostLoading.removeSelectedImage = false;
        });
    }

    function getSendEstimationDetails() {
      if (typeof router.query.phaseId === "string") {
        router.query.phaseId = [router.query.phaseId];
      }
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        project_id: router.query.projectId,
        phases: router.query.phaseId,
      };
      state.ui.isLoading.loadDetailsData = true;
      ethitransService
        .getSendEstimationDetails(payLoad)
        .then((res: any) => {
          console.log("res here", res);
          state.data.projectEstimations.name = res.data.data.project.name;
          state.data.projectEstimations.description =
            res.data.data.project.description;
          state.data.projectEstimations.estimatedBudget =
            res.data.data.payerRequest.budget;
          state.data.projectEstimations.startDate =
            res.data.data.payerRequest.start_date;
          state.data.projectEstimations.endDate =
            res.data.data.payerRequest.end_date;
          state.data.projectEstimations.attachments = res.data.data.attachments;
          state.data.projectEstimations.edocLink =
            res.data.data.payerRequest.edoc_id;
          state.data.sendarSignature = res.data.data.payerRequest.signature;
          res.data.data.phases.forEach((phase: any) => {
            phase.startDate = common.localDateOnly(phase.start_date);
            phase.endDate = common.localDateOnly(phase.end_date);
            phase.child_phases.forEach((child: any) => {
              child.startDate = common.localDateOnly(child.start_date);
              child.endDate = common.localDateOnly(child.end_date);
            });
          });
          state.data.sendPhases = res.data.data.phases;
          res.data.data.payers.forEach((user: any) => {
            const data = {
              email: user.email,
              // eslint-disable-next-line @typescript-eslint/camelcase
              first_name: user.first_name,
              // eslint-disable-next-line @typescript-eslint/camelcase
              middle_name: user.middle_name,
              // eslint-disable-next-line @typescript-eslint/camelcase
              last_name: user.last_name,
              // eslint-disable-next-line @typescript-eslint/camelcase
              address_line1: user.address_line1,
              code: user.email,
              // eslint-disable-next-line @typescript-eslint/camelcase
              phone: user.phone,
              isEdit: false,
            };
            state.data.allCustomer.push(data);
          });
          res.data.data.payer_emails.forEach((user: any) => {
            console.log("Payers Information >>> ", user);
            const data = {
              email: user.email,
              // eslint-disable-next-line @typescript-eslint/camelcase
              first_name: user.first_name,
              // eslint-disable-next-line @typescript-eslint/camelcase
              middle_name: user.middle_name,
              // eslint-disable-next-line @typescript-eslint/camelcase
              last_name: user.last_name,
              // eslint-disable-next-line @typescript-eslint/camelcase
              address_line1: user.address_line1,
              code: user.email,
              // eslint-disable-next-line @typescript-eslint/camelcase
              phone: (user.contact_number.length == 10) ? "1" + user.contact_number : user.contact_number,
              // eslint-disable-next-line @typescript-eslint/camelcase
              email_notification: user.mailNotification,
              // eslint-disable-next-line @typescript-eslint/camelcase
              sms_notification: user.smsNotification,
              isEdit: false,
              emailError: "",
              phoneError: "",
            };
            state.data.selectedCustomers.push(data);
          });
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.ui.errors.errorList.approval)
            .then((res: any) => {
              state.ui.errors.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.errors.errorList.approval.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          state.ui.isLoading.loadDetailsData = false;
        });
    }

    function validateSendInvitation() {
      state.ui.errors.hasError = false;
      if (
        !state.data.uploadSignature && !state.data.signatureLink && !state.data.sendarSignature
      ) {
        state.ui.errors.errorList.approval.push("Use/Upload/Draw Your valid Signature");
        state.ui.isPostLoading.checkSignature = true;
      }
      if (state.data.selectedCustomers.length == 0) {
        state.ui.errors.errorList.approval.push("One customer is required.");
      }
      if (!state.data.projectEstimations.startDate) {
        state.ui.errors.errorList.approval.push("Start Date is required.");
      }
      if (
        !state.data.projectEstimations.estimatedBudget ||
        parseInt(state.data.projectEstimations.estimatedBudget) <= 0
      ) {
        state.ui.errors.errorList.approval.push("Budget is required.");
      }
      if (!state.data.projectEstimations.endDate) {
        state.ui.errors.errorList.approval.push("End Date is required.");
      }
      if (
        state.data.projectEstimations.startDate &&
        state.data.projectEstimations.endDate
      ) {
        const d1 = new Date(state.data.projectEstimations.startDate).getTime();
        const d2 = new Date(state.data.projectEstimations.endDate).getTime();
        if (d2 < d1) {
          state.ui.errors.errorList.approval.push(
            "Date is not in Valid Range."
          );
        }
      }
      const allIds: any = estimationItemIds.value;

      const mainIds = state.data.sendPhases.flatMap((phase: any) => phase.sub_items.map((item: any) => item.id));

      const unselectedIds: any = mainIds.length - allIds.length;

      console.log("CHECK TEH SEND ITEMS >>> ",state.data.sendPhases)
      console.log("CHECK Phases and items create list >>> ",allIds)
      console.log("Check the final ", unselectedIds)
      console.log("Check type ", typeof(unselectedIds))
      if (state.data.createPhase.length === 0) {
        state.ui.errors.errorList.approval.push("At least one phase is required.");
      } else if (unselectedIds != 0 && unselectedIds != 'NaN') {
        const message = `${unselectedIds} remaining to select.`;
        state.ui.errors.errorList.approval.push(message);
      }

      if (
        !state.ui.errors.errorList.approval &&
        !state.ui.errors.errorList.approval.length
      ) {
        state.ui.errors.hasError = false;
      } else if (state.ui.errors.errorList.approval.length != 0) {
        state.ui.errors.hasError = true;
      }
    }
    const iActiveValidation = computed(() => {
      const phases: boolean = state.data.createPhase.length === 0;
      const isPostLoading: boolean = state.ui.isPostLoading.sendEstimation;

      // Your logic based on phases and isPostLoading
      if (phases && isPostLoading) {
        // Return value when both conditions are true
        return true;
      } else {
        // Return value when both conditions are false
        return false;
      }
    });

    function sendInvitationEstimation(data: any) {
      // console.log("im", state.data.createPhase);
      state.ui.isPostLoading.saveEstimation = false;
      state.ui.isPostLoading.sendEstimation = false;

      state.ui.isLoading.isReadyToSend = false;
      // if (!signaturePad.isEmpty() && signaturePad.toData()) {
      //   state.data.uploadSignature = signaturePad.toDataURL();
      // } else {
      //   state.data.uploadSignature = "";
      //   state.data.sendarSignatureFileLink = state.data.sendarSignature;
      // }
      const selectPhases = [] as any;

      state.data.sendPhases.forEach((phase: any) => {
        phase.sub_items.forEach((item: any) => {
          if (item.isChecked == true) {
            selectPhases.push(item.id);
          }
        });
      });

      state.ui.errors.errorList.approval = [];
      // const mainIds = state.data.sendPhases.flatMap((phase: any) => phase.sub_items.map((item: any) => item.id));
      // console.log("CHECK THE IDS >>> ", mainIds)
      console.log("CHECK ALL SELECTED >>> ", state.data.selectedItemList)
      // console.log("HERE >>> ", hasValidIds.value)
      // if (hasValidIds.value === false) {
      //   state.ui.errors.errorList.approval.push("One Phase is required.");
      // }

      validateSendInvitation();

      if (
        state.ui.isPostLoading.saveEstimation ||
        state.ui.errors.errorList.approval.length != 0 ||
        state.ui.errors.hasError
      )
        return false;
      if (
        state.ui.isPostLoading.sendEstimation ||
        state.ui.errors.errorList.approval.length != 0 ||
        state.ui.errors.hasError
      )
        return false;
      // if (totalSendSubItem.value > totalSelectedSubItem.value) {
      //   state.ui.isLoading.isReadyToSend = true;
      //   return false;
      // }

      if (props.contractId) {
        console.log("contract::", state.data.createPhase);
        state.data.createPhase.forEach((phase: any) => {
          // eslint-disable-next-line @typescript-eslint/camelcase
          phase.start_date = phase.startDate ? phase.startDate : "";
          // eslint-disable-next-line @typescript-eslint/camelcase
          phase.end_date = phase.endDate ? phase.startDate : "";
          phase.files = phase.attachments ? phase.attachments : [];
          phase.groupTasks = [];
          phase.groupTasks = phase.child_phases;
          console.log('44', phase.groupTasks)
          phase.groupTasks.forEach((task: any) => {
            task.items = [];
            // eslint-disable-next-line @typescript-eslint/camelcase
            task.start_date = task.startDate ? phase.startDate : "";
            // eslint-disable-next-line @typescript-eslint/camelcase
            task.end_date = task.endDate ? phase.startDate : "";
            // eslint-disable-next-line @typescript-eslint/camelcase
            task.items = task.estimation_items.map((item: any) => item.id);
            // eslint-disable-next-line @typescript-eslint/camelcase
            task.estimation_items = task.estimation_items.map((item: any) => item.id);

            task.budget = task.budget ? task.budget : task.cost;
            // task.items = task.estimation_items;
          });

        });
      }
      // else {
      //   state.data.createPhase.forEach((phase: any) => {
      //     // eslint-disable-next-line @typescript-eslint/camelcase
      //     phase.start_date = phase.startDate ? phase.startDate : "";
      //     // eslint-disable-next-line @typescript-eslint/camelcase
      //     phase.end_date = phase.endDate ? phase.startDate : "";
      //     phase.files = phase.attachments ? phase.attachments : [];
      //     phase.groupTasks.forEach((task: any) => {
      //       // eslint-disable-next-line @typescript-eslint/camelcase
      //       task.start_date = task.startDate ? phase.startDate : "";
      //       // eslint-disable-next-line @typescript-eslint/camelcase
      //       task.end_date = task.endDate ? phase.startDate : "";
      //       // eslint-disable-next-line @typescript-eslint/camelcase
      //       task.items = task.estimation_items.map((item: any) => item.id);
      //       task.estimation_items = task.estimation_items.map((item: any) => item.id);
      //       task.budget = task.budget ? task.budget : task.cost;
      //     });
      //   });
      // }

      if (data === 0) {
        state.ui.isPostLoading.saveEstimation = true;
      } else {
        state.ui.isPostLoading.sendEstimation = true;
      }
      const payLoad = {
        status: data,
        // eslint-disable-next-line @typescript-eslint/camelcase
        estimation_id: state.data.estimationId,
        payers: state.data.selectedCustomers,
        phases: state.data.createPhase,
        logo: state.data.estimation.logo ? state.data.estimation.logo : state.data.companyInfo.logo,
        acknowledgement: acknowledgement.value,
        // eslint-disable-next-line @typescript-eslint/camelcase
        legal_statement: legalStatement.value,
        budget: state.data.projectEstimations.estimatedBudget,
        // eslint-disable-next-line @typescript-eslint/camelcase
        start_date: state.data.projectEstimations.startDate,
        // eslint-disable-next-line @typescript-eslint/camelcase
        end_date: state.data.projectEstimations.endDate,
        files: state.data.projectEstimations.attachments,
        // eslint-disable-next-line @typescript-eslint/camelcase
        edoc_id: state.data.projectEstimations.edocLink,
        // eslint-disable-next-line @typescript-eslint/camelcase
        individual_approval: state.data.individualAcceptance,
        // eslint-disable-next-line @typescript-eslint/camelcase
        approve_by_all: state.data.approveByAll,
        signature: state.data.uploadSignature,
        // eslint-disable-next-line @typescript-eslint/camelcase
        signature_link: state.data.sendarSignature ? state.data.sendarSignature : state.data.signatureLink,
      };
      console.log("PAYLOAD >>> ", payLoad)
      state.ui.isLoading.loadDetailsData = true;
      ethitransService
        .estimationSend(payLoad)
        .then((res: any) => {
          if (data === 0) {
            state.data.successFulMessage = "Smart Contract Saved Successfully.";
          } else {
            state.data.successFulMessage = "Smart Contract Sent Successfully.";
          }
          state.data.showSuccesCustomModal = !state.data.showSuccesCustomModal;
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.ui.errors.errorList.approval)
            .then((res: any) => {
              state.ui.errors.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.errors.errorList.approval.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          state.ui.isLoading.loadDetailsData = false;
          if (data === 0) {
            state.ui.isPostLoading.saveEstimation = true;
          } else {
            state.ui.isPostLoading.sendEstimation = true;
          }
          state.data.uploadSignature = "";
        });
    }

    function showUploadImageModel() {
      $("#updateUpload").modal("show");
    }

    function hideShowUploadImageModel() {
      $("#profileUpdate").val("");
      $("#updateUpload").modal("hide");
    }

    function readSignature(file: any) {
      const data = {} as any;
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const extension = file.name.substring(file.name.lastIndexOf(".") + 1);
        const extensionLower = extension.toLowerCase();
        data.base64 = e.target.result;
        data.duplicateFile = false; // data.fileName = file.name;
        data.fileName = file.name.replaceAll(`.${extension}`, "");
        data.extension = extensionLower;
        data.isuploded = false;
        data.tag = [] as any;
        data.size = common.formatBytes(parseInt(file.size));
        // state.data.uploadImageData = data;
        state.data.newUploadImge = data.base64;
        state.data.cropImage = data.base64;
        state.data.signatureFileName = data.fileName;
      };
      reader.readAsDataURL(file);
    }

    function readLogo(file: any) {
      const data = {} as any;
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const extension = file.name.substring(file.name.lastIndexOf(".") + 1);
        const extensionLower = extension.toLowerCase();
        data.file = e.target.result;
        data.filename = file.name.replaceAll(`.${extension}`, "");
        data.extension = extensionLower;
        data.isuploded = false;
        data.size = common.formatBytes(parseInt(file.size));
        state.data.estimation.logo = data.file;
        console.log('READ LOGO DATA Check>>>', state.data.estimation.logo)
      };
      reader.readAsDataURL(file);
    }

    function onDocumentChange(event: any) {
      const files = event.target.files || event.dataTransfer.files;
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        // console.log("FILE CHECK >>>", file);
        // if(file.type)
        const extension = file.name.substring(file.name.lastIndexOf(".") + 1);
        const extensionLower = extension.toLowerCase();
        readSignature(file);
        $("#updateUpload").modal("show");
      }
    }

    function uploadLogo(event: any) {
      const files = event.target.files || event.dataTransfer.files;
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        // console.log("FILE CHECK >>>", file);
        // if(file.type)
        const extension = file.name.substring(file.name.lastIndexOf(".") + 1);
        const extensionLower = extension.toLowerCase();
        readLogo(file);
      }
    }

    function saveSignature() {
      hideShowUploadImageModel();
    }

    function clearSignature() {
      state.data.cropSignature = "";
    }



    function enableSignaturPad() {
      state.ui.isLoading.signaturePadLoad =
        !state.ui.isLoading.signaturePadLoad;

    }
    // state.data.sendPhases
    function getJobEstimationDetails() {
      state.ui.isLoading.loadDetailsData = true;
      ethitransService
        .getJobEstimationDetails(state.data.estimationId)
        .then((res: any) => {
          console.log('The job estimation details is:', res.data.data)
          state.data.projectEstimations.name = res.data.data.project.name;
          state.data.projectId = res.data.data.project.uuid;
          state.data.projectEstimations.description =
            res.data.data.project.description;
          state.data.projectEstimations.estimatedBudget =
            res.data.data.total_amount;
          state.data.sendarSignature = res.data.data.estimation.signature;
          state.data.estimation = res.data.data.estimation;

          if (props.contractId && props.estimationId) {
            res.data.data.phases.forEach((phase: any) => {
              phase.isChecked = true;
              phase.isDisabled = true;
              phase.budget = phase.estimation_amount;
              // phase.startDate = common.localDateOnly(phase.start_date);
              // phase.endDate = common.localDateOnly(phase.end_date);
              phase.sub_items.forEach((child: any) => {
                child.isChecked = true;
                child.isDisabled = true;
                // child.isChecked = true;
                child.budget = child.cost;
                // child.startDate = common.localDateOnly(child.start_date);
                // child.endDate = common.localDateOnly(child.end_date);
                // state.data.sendPhases.push(child);
              });
            });

            // res.data.data.phases.forEach((phase: any) => {
            //   phase.isChecked = false;
            //   phase.isDisabled = false;
            //   phase.budget = phase.estimation_amount;
            //   // phase.startDate = common.localDateOnly(phase.start_date);
            //   // phase.endDate = common.localDateOnly(phase.end_date);
            //   phase.sub_items.forEach((child: any) => {
            //     child.isChecked = false;
            //   child.isDisabled = false;
            //     // child.isChecked = true;
            //     child.budget = child.cost
            //     // child.startDate = common.localDateOnly(child.start_date);
            //     // child.endDate = common.localDateOnly(child.end_date);
            //     // state.data.sendPhases.push(child);
            //   });
            // });
          } else if (props.estimationId) {
            res.data.data.phases.forEach((phase: any) => {
              phase.isChecked = false;
              phase.isDisabled = false;
              phase.budget = phase.estimation_amount;
              // phase.startDate = common.localDateOnly(phase.start_date);
              // phase.endDate = common.localDateOnly(phase.end_date);
              phase.sub_items.forEach((child: any) => {
                child.isChecked = false;
                child.isDisabled = false;
                // child.isChecked = true;
                child.budget = child.cost;
                // child.startDate = common.localDateOnly(child.start_date);
                // child.endDate = common.localDateOnly(child.end_date);
                // state.data.sendPhases.push(child);
              });
            });
          }
          // console.log("After here", res.data.data.phases);
          state.data.sendPhases = res.data.data.phases;
          console.log("RESULT here", state.data.sendPhases);
          res.data.data.payers.forEach((user: any) => {
            const data = {
              email: user.email,
              // eslint-disable-next-line @typescript-eslint/camelcase
              first_name: user.first_name,
              // eslint-disable-next-line @typescript-eslint/camelcase
              middle_name: user.middle_name,
              // eslint-disable-next-line @typescript-eslint/camelcase
              last_name: user.last_name,
              // eslint-disable-next-line @typescript-eslint/camelcase
              address_line1: user.address_line1,
              code: user.email,
              // eslint-disable-next-line @typescript-eslint/camelcase
              phone: user.phone,
              isEdit: false,
            };
            state.data.allCustomer.push(data);
          });
          res.data.data.payers.forEach((user: any) => {
            console.log("Payer Email >>> ", user)
            const data = {
              email: user.email,
              // eslint-disable-next-line @typescript-eslint/camelcase
              first_name: user.first_name,
              // eslint-disable-next-line @typescript-eslint/camelcase
              middle_name: user.middle_name,
              // eslint-disable-next-line @typescript-eslint/camelcase
              last_name: user.last_name,
              // eslint-disable-next-line @typescript-eslint/camelcase
              address_line1: user.address_line1,
              code: user.email,
              // eslint-disable-next-line @typescript-eslint/camelcase
              phone: user.contact_number,
              // eslint-disable-next-line @typescript-eslint/camelcase
              email_notification: false,
              // eslint-disable-next-line @typescript-eslint/camelcase
              sms_notification: false,
              mailNotification: false,
              smsNotification: false,
              isEdit: false,
              emailError: "",
              phoneError: "",
            };
            if (user.email) {
              data.mailNotification = true;
            }
            state.data.selectedCustomers.push(data);
          });
          console.log("CHECK THE PAYERS >>> ", res.data.data.payers)
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.ui.isLoading.loadDetailsData = false;
        });
    }

    function showAddPhaseModal() {
      state.ui.errors.errorList.approval = [];
      state.ui.isLoading.projectStartDate = false;
      state.ui.isLoading.projectEndDate = false;
      state.ui.errors.hasError = false;
      if (!state.data.projectEstimations.startDate) {
        state.ui.isLoading.projectStartDate = true;
        state.ui.errors.hasError = true;
        state.ui.errors.errorList.approval.push("Start Date is required.");
      }
      if (!state.data.projectEstimations.endDate) {
        state.ui.isLoading.projectEndDate = true;
        state.ui.errors.hasError = true;
        state.ui.errors.errorList.approval.push("End Date is required.");
      }
      if (
        state.data.projectEstimations.startDate &&
        state.data.projectEstimations.endDate
      ) {
        state.data.showAddPhaseModal = !state.data.showAddPhaseModal;
      }
    }
    function resetAddPhaseModal() {
      state.data.createPhase[state.data.selectedIndex] = state.data.editData;
    }

    function closeAddPhaseModal() {
      state.data.editMode = false;
      state.data.selectedIndex = "";

      state.data.selectedTaskIndex = "";
      state.data.showAddPhaseModal = !state.data.showAddPhaseModal;
    }

    function addNewPhase(data: any) {
      console.log("addNewPhase", data);
      // to set the selected while createing the phase
      data.groupTasks.forEach((obj: any) => {
        if (obj.items && Array.isArray(obj.items)) {
          obj.items.forEach((item: any) => {
            if (item.id) {
              state.data.selectedItemList.push(item.id);
            }
          });
        }
      });

      // console.log("addNewPhase", state.data.selectedItemList);


      state.data.editData = {};
      data.startDate = common.localDateOnly(data.startDate);
      data.endDate = common.localDateOnly(data.endDate);
      state.data.createPhase.push(data);
      console.log("ADD Beofre >>", state.data.sendPhases);
      state.data.sendPhases.forEach((item: any) => {
        state.data.createPhase.forEach((phase: any) => {
          phase.tasks.forEach((task: any) => {
            console.log("<<>>");

            if (task.mainName === item.name) {
              console.log("ADD MODIFIED");
              item.isDisabled = true;
              item.isChecked = true;
              item.sub_items.forEach((sub: any) => {
                sub.isDisabled = true;
                sub.isChecked = true;
              });
            }
          });
        });
      });
      console.log("After >>", state.data.sendPhases);
    }

    function editNewPhase(data: any, index: any) {
      // state.data.createPhase
      console.log("Check >>>", data);
      console.log("Check >>>", data.tasks);
      state.data.editMode = true;
      state.data.selectedIndex = index;
      state.data.editFrom = "phase";
      state.data.editData = data;
      showAddPhaseModal();
    }

    function editTask(data: any, phaseIndex: any, taskIndex: any) {
      state.data.editMode = true;
      state.data.editFrom = "task";
      state.data.selectedIndex = phaseIndex;
      state.data.selectedTaskIndex = taskIndex;
      state.data.editData = data;
      showAddPhaseModal();
    }

    function resetChecked(data: any) {
      console.log("RESET >>> ", data)
      state.data.sendPhases = data;
    }
    function updatePhase(data: any) {
      // alert("PRABHU HELLO")
      // eslint-disable-next-line @typescript-eslint/camelcase
      data.child_phases = data.groupTasks;
      
      // state.data.sendPhases // need to set here
      // console.log("im om", state.data.createPhase[state.data.selectedIndex]);

      data.groupTasks.forEach((obj: any) => {
        console.log("CHECK THE OBJESTION IN GEROUP TASKS >>> ", obj)

        if (obj.estimation_items && Array.isArray(obj.estimation_items)) {
          obj.estimation_items.forEach((item: any) => {
            if (item.id) {
              state.data.selectedItemList = common.addUniqueNumber(state.data.selectedItemList, item.id)
              // state.data.selectedItemList.push(item.id);
            }
          });
        }
      });
      // data.child_phases.forEach((obj: any) => {
      //   console.log("CHECK THE OBJESTION IN GEROUP TASKS >>> ", obj)

      //   if (obj.estimation_items && Array.isArray(obj.estimation_items)) {
      //     obj.estimation_items.forEach((item: any) => {
      //       if (item.id) {
      //         state.data.selectedItemList = common.addUniqueNumber(state.data.selectedItemList, item.id)
      //         // state.data.selectedItemList.push(item.id);
      //       }
      //     });
      //   }
      // });
      state.data.createPhase[state.data.selectedIndex] = data;


      // // update here
      // state.data.sendPhases.forEach((item: any) => {
      //   state.data.createPhase.forEach((phase: any) => {
      //     phase.tasks.forEach((task: any) => {
      //       console.log("<<>>");

      //       if (task.mainName === item.name) {
      //         console.log("ADD MODIFIED");
      //         item.isDisabled = true;
      //         item.isChecked = true;
      //         item.sub_items.forEach((sub: any) => {
      //           sub.isDisabled = true;
      //           sub.isChecked = true;
      //         });
      //       }
      //     });
      //   });
      // });
      // // state.data.createPhase.tasks
      // console.log("item delete222", state.data.selectedItemList);
      // console.log("CHECK THE INPUT DATA >>>", data);
      // console.log(
      //   "CHECK THE INPUT DATA >>>",
      //   state.data.createPhase[state.data.selectedIndex].tasks
      // );
      // console.log("Beofre >>", state.data.sendPhases);
      // new code
      // state.data.sendPhases.forEach((lineItem: any)=> {
      //   lineItem.sub_items.forEach((subItem: any) => {

      //     const found = state.data.createPhase[state.data.selectedIndex].tasks.some(
      //             (item: any) => item.id === subItem.id
      //           );

      //           if(found){
      //             lineItem.isDisabled = false;
      //             lineItem.isChecked = true;
      //           }
      //   })
      // })

      //old code
      // state.data.sendPhases.forEach((item: any)=> {
      //   state.data.createPhase.forEach((phase: any)=> {
      //     phase.tasks.forEach((task: any)=> {
      //     console.log("<<>>")

      //     if(task.mainName === item.name){
      //       console.log("ADD MODIFIED")
      //       item.isDisabled = true;
      //               item.isChecked = true;
      //               item.sub_items.forEach((sub: any)=> {
      //                 sub.isDisabled = true;
      //               sub.isChecked = true;
      //               })
      //     } else {
      //       item.isDisabled = false;
      //               item.isChecked = false;
      //               item.sub_items.forEach((sub: any)=> {
      //                 sub.isDisabled = false;
      //               sub.isChecked = false;
      //               })
      //     }
      //   })
      // })
      // })
      console.log("After >>", state.data.sendPhases);
    }

    function updateTask(data: any) {
      data.startDate = common.localDateOnly(data.startDate);
      data.endDate = common.localDateOnly(data.endDate);
      state.data.createPhase[state.data.selectedIndex].tasks[
        state.data.selectedTaskIndex
      ] = data;
    }
    function selectForDeletePhase(item: any) {
      console.log("item delete", item);
      // state.data.createPhase
      // const deleteId = item.child_phases.flatMap((gTask: any) =>
      //   gTask.estimation_items.map((item: any) => item.id)
      // );
      // state.data.selectedItemList = state.data.selectedItemList.filter(
      //   (item: any) => !deleteId.includes(item)
      // );
      // // console.log("item delete", state.data.selectedItemList);

      state.data.selectedforDelete = item;
      state.data.showDeletePhaseConfirmation =
        !state.data.showDeletePhaseConfirmation;
    }

    function removeSelectedPhase() {
      // reset of main Item
      // state.data.sendPhases
      const item = state.data.selectedforDelete
        ? state.data.selectedforDelete
        : ([] as any);

        console.log("Before >>> ",  state.data.sendPhases)
      state.data.sendPhases.forEach((mainItem: any) => {
        mainItem.sub_items.forEach((subItem: any) => {
          item.tasks.forEach((selectedPhase: any) => {
            if (
              selectedPhase.main_item_id == subItem.main_item_id &&
              !subItem.item.parent_id
            ) {
              mainItem.isChecked = false;
              mainItem.isDisabled = false;
            }
            // for SubItem Checked
            if (subItem.main_item_id === selectedPhase.main_item_id) {
              subItem.isChecked = false;
              subItem.isDisabled = false;
            }
          });
        });
      });
      console.log("After >>> ",  state.data.sendPhases)
      state.data.createPhase = state.data.createPhase.filter(
        (e: any) => e != item
      );
    }

    function removeTask(phaseIndex: any, taskIndex: any) {
      state.data.createPhase[phaseIndex].tasks.splice(taskIndex, 1);
    }

    function showCreateCompanyModel() {
      $("#addCompanyInfo").modal("show");
    }

    function hideCreateCompanyModel() {
      state.ui.errors.errorList.createCompanyIfno = [];
      $("#addCompanyInfo").modal("hide");
      state.ui.isLoading.isCompanyEdit = false;
    }
    function editCompany() {
      state.ui.isLoading.isCompanyEdit = !state.ui.isLoading.isCompanyEdit;
      $("#addCompanyInfo").modal("show");
    }
    function validateCreateCompany() {
      // console.log("*** TODO VALIDATION  ***");
      state.ui.errors.hasError = false;
      state.ui.errors.errorList.createCompanyIfno = [];
      if (
        !state.data.companyInfo.company_name &&
        !state.ui.isLoading.isCompanyEdit
      ) {
        state.ui.errors.errorList.createCompanyIfno.push(
          "Company name is required."
        );
      }
      if (
        !state.data.companyInfo.company_email &&
        !state.ui.isLoading.isCompanyEdit
      ) {
        state.ui.errors.errorList.createCompanyIfno.push(
          "Company Email is required."
        );
      }
      if (!state.data.companyInfo.logo) {
        state.ui.errors.errorList.createCompanyIfno.push(
          "Company logo is required."
        );
      }
      if (!state.data.companyInfo.acknowledgement) {
        state.ui.errors.errorList.createCompanyIfno.push(
          "Company acknowledgement is required."
        );
      }
      // eslint-disable-next-line @typescript-eslint/camelcase
      if (!state.data.companyInfo.legal_statement) {
        state.ui.errors.errorList.createCompanyIfno.push(
          "Company legal statement is required."
        );
      }
      if (
        !state.ui.errors.errorList.createCompanyIfno &&
        !state.ui.errors.errorList.createCompanyIfno.length
      ) {
        state.ui.errors.hasError = false;
      } else if (state.ui.errors.errorList.createCompanyIfno.length != 0) {
        state.ui.errors.hasError = true;
      }
    }
    //no profile set
    function createCompanyIfno() {
      state.ui.errors.errorList.createCompanyIfno = [];
      state.ui.errors.hasError = false;

      validateCreateCompany();

      if (state.ui.isPostLoading.createCompanyIfno) return false;
      if (
        state.ui.errors.hasError ||
        state.ui.errors.errorList.createCompanyIfno.length != 0
      )
        return false;

      if (state.ui.isLoading.isCompanyEdit) {
        hideCreateCompanyModel();
      }
      const payLoad: any = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        company_name: state.data.companyInfo.company_name,
        // eslint-disable-next-line @typescript-eslint/camelcase
        logo: state.data.companyInfo.logo,
        // eslint-disable-next-line @typescript-eslint/camelcase
        company_phone_number: state.data.companyInfo.company_phone_number,
        // eslint-disable-next-line @typescript-eslint/camelcase
        company_email: state.data.companyInfo.company_email,
        // eslint-disable-next-line @typescript-eslint/camelcase
        acknowledgement: state.data.companyInfo.acknowledgement,
        // eslint-disable-next-line @typescript-eslint/camelcase
        legal_statement: state.data.companyInfo.legal_statement,
      };
      state.ui.isPostLoading.createCompanyIfno = true;
      ethitransService
        .updateCompanyInfo(payLoad)
        .then((res: any) => {
          hideCreateCompanyModel();
        })
        .catch((error: any) => {
          console.log(error);
          common
            .sdCatchErr(error, state.ui.errors.errorList.createCompanyIfno)
            .then((res: any) => {
              state.ui.errors.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.errors.errorList.createCompanyIfno.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          state.ui.isPostLoading.createCompanyIfno = false;
        });
    }

    function getSmartContractDetails() {
      state.ui.isLoading.getSmartContractDetails = true;
      ethitransService
        .getSmartContractDetails(props.contractId)
        .then((res: any) => {
          // approve by all and indivudal approval settings
          console.log("DATA RESPONSE CHECK >>>", res.data.data);
          if (res.data.data.individual_approval === 1) {
            state.data.individualAcceptance = true;
          }
          if (res.data.data.approve_by_all === 1) {
            state.data.approveByAll = true;
          }
          state.data.projectEstimations.startDate = common.localDateOnly(
            res.data.data.start_date
          );
          state.data.projectEstimations.endDate = common.localDateOnly(
            res.data.data.end_date
          );
          const todayDate = new Date(); //Today Date
          const dateOne = new Date(state.data.projectEstimations.startDate);
          if (todayDate > dateOne) {
            state.data.todayData = state.data.projectEstimations.startDate;
          }
          state.data.projectEstimations.attachments = res.data.data.attachments;
          state.data.projectEstimations.edocLink = res.data.data.edoc_id;
          // correction of variable required.
          console.log("<<< I AM IN getSmartContractDetails  >>> ", res.data.data.phases)
          state.data.createPhase = res.data.data.phases;
          state.data.tempPhase = JSON.parse(
            JSON.stringify(res.data.data.phases)
          );
          // company information mapping
          state.data.companyInfo.logo = res.data.data.logo;
          state.data.companyInfo.acknowledgement =
            res.data.data.acknowledgement;
          // eslint-disable-next-line @typescript-eslint/camelcase
          state.data.companyInfo.legal_statement = res.data.data.legal_statement;
          state.data.createPhase.forEach((phase: any) => {
            phase.attachments.forEach((child: any) => {
              child.file = `${config.imageBaseUrl}${child.file_link}`;
              child.name = child.file_name;
              child.extension = child.file_type;
            });
            phase.isChecked = false;
            phase.isDisabled = false;

            // eslint-disable-next-line @typescript-eslint/camelcase
            phase.sub_items = phase.child_phases;
            // eslint-disable-next-line @typescript-eslint/camelcase
            phase.sub_items.forEach((child: any) => {
              child.isChecked = false;
              child.isDisabled = false;
              // child.isChecked = true;
              // child.startDate = common.localDateOnly(child.start_date);
              // child.endDate = common.localDateOnly(child.end_date);
              // state.data.sendPhases.push(child);
            });
            phase.tasks = phase.child_phases;

            // childphase > tasks
            // eslint-disable-next-line @typescript-eslint/camelcase
            phase.startDate = common.localDateOnly(phase.start_date);
            // eslint-disable-next-line @typescript-eslint/camelcase
            phase.endDate = common.localDateOnly(phase.end_date);
            // phase.isChecked == true
          });
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.ui.isLoading.getSmartContractDetails = false;
        });
    }

    function usdPriceFormate(data: any, type: any) {
      if (data != null) {
        if (type == 1) {
          return data.toLocaleString('en-US', {
            minimumFractionDigits: 2,
          }) + '%';
        } else {
          return data.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2,
          });
        }

      }
    }

    onBeforeRouteLeave((to, from) => {
      closeAddPhaseModal();
      state.data.showAddPhaseModal = false;
      $("#successfulModal").modal("hide");
      state.data.showDeletePhaseConfirmation = false;
      hideDelete();
      hideShowUploadImageModel();
      hideCreateCompanyModel();
    });

    function userSignature(from: any) {
      ethitransService
        .userSignature()
        .then((res: any) => {
          if (from == 'onMount' && res.data.data.signature) {
            state.data.haveProfileSign = true;
          }
          if (from == 'use' && res.data.data.signature) {
            state.data.useProfileSign = true;
            state.data.uploadSignature = "";
            state.data.sendarSignature = "";
            state.data.cropSignature = res.data.data.signature;
            state.data.signatureLink = res.data.data.signature;
          }
        })
        .catch((error: any) => {
          //   common
        })
        .finally(() => {
          //   state.ui.isLoading.userStatus = true;
        });
    }

    onMounted(() => {
      const userData: any = localStorage.getItem("current_user_info");
      const company: any = JSON.parse(userData).company
        ? JSON.parse(userData).company
        : "";
      state.data.companyInfo.logo = company.logo;
      // eslint-disable-next-line @typescript-eslint/camelcase
      state.data.companyInfo.company_name = company.company_name;
      // eslint-disable-next-line @typescript-eslint/camelcase
      state.data.companyInfo.company_phone_number = company.company_phone_number;
      // eslint-disable-next-line @typescript-eslint/camelcase
      state.data.companyInfo.company_email = company.company_email;
      state.data.companyInfo.acknowledgement = company.acknowledgement;
      // eslint-disable-next-line @typescript-eslint/camelcase
      state.data.companyInfo.legal_statement = company.legal_statement;
      if (props.contractId) {
        state.data.contractId = props.contractId;
        getSmartContractDetails();
      }
      state.data.estimationId = props.estimationId;
      getJobEstimationDetails();
      totalSendSubItem;
      totalSelectedSubItem;
      userSignature('onMount');

      // getSendEstimationDetails();
    });
    return {
      state,
      estimationItemIds,
      sendPhaseIds,
      redirectToSmartContractDashboard,
      resetAddPhaseModal,
      hasValidIds,
      isMatchingItems,
      iActiveValidation,
      setLocalStorageCompanyProfile,
      removeSelectedAttachment,
      selectForDeletePhase,
      hideConfirmation,
      addPhaseStatus,
      getSendEstimationDetails,
      removeSelectedImage,
      dropFiles,
      addCustomerEmail,
      removeSelectCustomer,
      editSelectCustomer,
      updateSelectCustomer,
      closeSuccessCustomModal,
      showDelete,
      hideDelete,
      isEmailValid,
      isPhoneValid,
      isInvalid,
      changeValue,
      removeUploadSelectedImage,
      sendInvitationEstimation,
      validateSendInvitation,
      redirectToProjectToDo,
      canvas,
      clearSignature,
      getUnselectedIds,
      readImage,
      readSignature,
      onDocumentChange,
      showUploadImageModel,
      hideShowUploadImageModel,
      saveSignature,
      enableSignaturPad,
      showAddPhaseModal,
      addNewPhase,
      removeSelectedPhase,
      editNewPhase,
      closeAddPhaseModal,
      updatePhase,
      resetChecked,
      editTask,
      updateTask,
      removeTask,
      uploadLogo,
      readLogo,
      hideCreateCompanyModel,
      showCreateCompanyModel,
      createCompanyIfno,
      validateCreateCompany,
      editCompany,
      totalSendSubItem,
      totalSelectedSubItem,
      redirectToSmartContractList,
      usdPriceFormate,
      showSignaturePad,
      hideSignaturePad,
      uploadSignature,
      uploadSignatureFile,
      hideSignatureUpload,
      userSignature,
      acknowledgement,
      legalStatement
    };
  },
});
